import {FieldMap, FieldOperations, OpsCategories, SqlOperation, TableI} from "../../Types";
import moment from "moment";
import {nanoid} from "@reduxjs/toolkit";


let fieldOperations: FieldOperations = {
    id: [...OpsCategories.eq, ...OpsCategories.range, ...OpsCategories.in,],
    dt: [...OpsCategories.range],
    seminar_id: [...OpsCategories.in],
    participation: [...OpsCategories.in],
    attended: [...OpsCategories.eq],
    was_registred: [...OpsCategories.eq],
    have_double_bonus: [...OpsCategories.eq],
    is_confirmed_user: [...OpsCategories.eq],
    full_name: [...OpsCategories.like],
    msisdn: [...OpsCategories.like],
    email: [...OpsCategories.like],
    // title: [...OpsCategories.like],
    // start_timestamp: [...OpsCategories.range],
    // end_timestamp: [...OpsCategories.range],
};

export function seminarRegs287DefFilters() {
    return [
        {
            nanoid: nanoid(),
            field: 'dt',
            type: 'datetime',
            filterOp: SqlOperation.GREATER_THAN_OR_EQUAL,
            locked: true,
            value: moment("2024-10-01").startOf('day').format('yyyy-MM-DD HH:mm:ss')
        },
        {
            nanoid: nanoid(),
            field: 'dt',
            type: 'datetime',
            filterOp: SqlOperation.LESS_THAN_OR_EQUAL,
            locked: true,
            value: moment().endOf('day').locale('en').format('yyyy-MM-DD HH:mm:ss')
        },
        {
            nanoid: nanoid(),
            field: 'seminar_id',
            type: 'map',
            filterOp: SqlOperation.IN,
            locked: true,
            value: []
        }
    ]
}

export const SeminarRegsTable: TableI = {
    backend: "/287/",
    title: {
        ua: "Реєстрації на семінари",
        en: "Замовлення",
    },
    pk: 'id',
    defaultOrderedBy: "id",
    defaultOrderedWay: "DESC",
    defaultPerPage: 20,
    orderByFields: {
        "id": {ua: "ID", en: "№"},
        "dt": {ua: "Створено", ru: "Дата"},
    },
    fieldOperations: fieldOperations,
    defaultFilters: 'seminarRegs287DefFilters',
    fields: [
        {
            key: "n",
            inline: true,
            title: {ua: "№", ru: "№"}, type: "number",
            applyTransform: true
        },
        {
            key: "seminar_id", title: {ua: "Назва сему", ru: "ID"},
            detailed: true,
            inline: true,
            applyTransform: true,
            type: "map",
            map: {
                async: 'all_seminars'
            } as FieldMap,
        },
        // {
        //     key: "sem_name",
        //     title: {ua: "Назва семінару", ru: "Назва семінару"},
        //     type: 'string',
        //     detailed: true,
        //     editable: true,
        //     inline: true,
        // },
        {
            key: "sem_time",
            title: {ua: "Дата сему", ru: "Дата сему"},
            type: "datetime",
            datetimeFormat: "DD MMMM YYYY HH:mm",
            applyTransform: true,
            detailed: true,
            inline: true,
        },
        {
            key: "full_name",
            title: {ua: "Повне ім'я", ru: "Фамилия И.П."},
            type: 'string',
            detailed: true,
            editable: true,
            inline: true,
        },
        {
            key: "msisdn", type: 'tel',
            applyTransform: true,
            detailed: false, inline: true, title: {ua: "Телефон", ru: "Телефон"}
        },
        {
            key: "email",
            type: 'email',
            applyTransform: true,
            title: {ua: "Email", ru: "Email"},
            detailed: true,
            inline: true,
            editable: true,
        },
        {
            key: "participation",
            title: {ua: "Участь", ru: "Email"},
            detailed: true,
            inline: true,
            editable: true,
            applyTransform: true,
            type: "map",
            map: {
                backgroundColor: '#18111c',
                values: {
                    "offline": {
                        rawValue: "offline",
                        lang: {
                            ua: "ОФЛАЙН",
                            en: "START"
                        },
                        color: '#007efb'
                    },
                    "online": {
                        rawValue: "online",
                        lang: {
                            ua: "ОНЛАЙН",
                            en: "SMART"
                        },
                        color: '#f132d3'
                    }
                }
            },
        },
        {
            key: "attended",
            title: {ua: "Відвідав", ru: "Тема"},
            type: "boolean",
            applyTransform: true,
            inline: true,
            detailed: true,
            inlineEditable: true,
            editable: true
        }, {
            key: "have_double_bonus",
            title: {ua: "Подвійний бонус", ru: "Нова Пошта"},
            type: "boolean",
            applyTransform: true,
            inline: false,
            detailed: true,
        }, {
            key: "was_registred",
            title: {ua: "Зареєстр. на час запису", ru: "Нова Пошта"},
            type: "boolean",
            applyTransform: true,
            inline: false,
            detailed: true,
        }, {
            key: "user_exists",
            title: {ua: "Зареєстр. зараз", ru: "Нова Пошта"},
            type: "boolean",
            applyTransform: true,
            inline: false,
            detailed: true,
        }, {
            key: "is_confirmed_user",
            title: {ua: "Підтверджений монтаж.", ru: "Нова Пошта"},
            type: "boolean",
            applyTransform: true,
            inline: false,
            detailed: true,
        },

        {
            key: "id",
            detailed: true,
            title: {ua: "ID", ru: "№"},
            type: "number",
            applyTransform: true,
        },
        {
            key: "dt",
            title: {ua: "Створено", ru: "Дата"},
            type: "datetime",
            datetimeFormat: "DD MMMM YYYY HH:mm",
            applyTransform: true,
            inline: false,
            detailed: true
        },
    ]
}
