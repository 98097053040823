import styled from "styled-components";
import React from "react";
import {useAppDispatch} from "../hooks";
import {changePage} from "../features/tables/tableSlice";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAngleLeft, faAngleRight, faAnglesLeft, faAnglesRight,} from "@fortawesome/free-solid-svg-icons";
import TablePerPage from "./TablePerPage";

function TablePagination({maxPages, currentPage, cols, inConditionRecords, tableName}: {
    maxPages: number,
    currentPage: number,
    cols: number,
    inConditionRecords: number | undefined,
    tableName: string
}) {

    const dispatch = useAppDispatch()

    function onPageChange(pageIndex: number) {
        dispatch(changePage(pageIndex))
    }

    // Calculate the range of pages to display, with a maximum of 5 pages
    const pageRange = 2; // Display two pages before and after the current page
    let startPage = Math.max(currentPage - pageRange, 1); // Ensure we don't go below 1
    let endPage = Math.min(currentPage + pageRange, maxPages); // Ensure we don't go above maxPages

    // Adjust the range if the current page is too close to the edges
    if (currentPage <= pageRange) {
        endPage = Math.min(5, maxPages); // Show the first 5 pages when near the start
    } else if (currentPage >= maxPages - pageRange) {
        startPage = Math.max(maxPages - 4, 1); // Show the last 5 pages when near the end
    }

    const pages = Array.from({length: endPage - startPage + 1}, (_, i) => startPage + i);

    return (
        <td colSpan={cols}>
            <Wrapper>
                <PagesList>
                    {maxPages > 5 && <li>
                        <Btn onClick={() => onPageChange(1)}>
                            <FontAwesomeIcon title={'sort'} icon={faAnglesLeft}
                                             className={'fa-color-text'}/>
                        </Btn>
                    </li>}
                    <li>
                        <Btn onClick={() => onPageChange(currentPage - 1)} disabled={currentPage === 1}>
                            <FontAwesomeIcon title={'sort'} icon={faAngleLeft}
                                             className={'fa-color-text'}/>
                        </Btn>
                    </li>
                    {pages.map(p => (
                        <li key={p}>
                            <Btn $active={currentPage === p} onClick={() => onPageChange(p)}
                                 disabled={currentPage === p}>{p}</Btn>
                        </li>
                    ))}
                    <li>
                        <Btn onClick={() => onPageChange(currentPage + 1)}
                             disabled={currentPage === maxPages}>
                            <FontAwesomeIcon title={'sort'} icon={faAngleRight}
                                             className={'fa-color-text'}/>
                        </Btn>
                    </li>
                    {maxPages > 5 && <li>
                        <Btn onClick={() => onPageChange(maxPages)}>
                            <FontAwesomeIcon title={'sort'} icon={faAnglesRight}
                                             className={'fa-color-text'}/>
                        </Btn>
                    </li>}
                </PagesList>
                {/*<div className={'pages'}>*/}
                {/*    <img src="/win-icons/Book 2.ico"*/}
                {/*         alt="book"/><span>Сторінок - {maxPages}</span>*/}
                {/*</div>*/}
                <div className={'per-page'}>
                    <TablePerPage tableName={tableName}/>
                </div>
                <div className={'total'}>Усього записів - {inConditionRecords}</div>
            </Wrapper>
        </td>
    )
        ;
}

export default TablePagination;

const Wrapper = styled.div`
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    

    & .total {
        display: flex;
        align-items: center;
        margin-right: .5rem;
        margin-left: auto;
    }
    
    & .per-page {
        display: flex;
        align-items: center;
    }

    & .pages {
        display: inline-flex;
        align-items: center;
        gap: .2rem;
        margin-left: .5rem;
        & img {
            width: 32px;
            height: 32px;
        }
    }
`

const PagesList = styled.ul`
    list-style: none;
    margin: 0;
    padding: 0;

    display: inline-flex;
    align-items: center;
    gap: 0;
    


    & > li {
        margin: 0;
        //border-right: 2px solid var(--table-border-color);
        //border-bottom: 2px solid var(--table-border-color);
        //border-top: 2px solid var(--table-border-color);

        & button {

        }
    }


    & li:first-of-type {
        //border-left: 2px solid var(--table-border-color);
        //border-top-left-radius: 1rem;
        //border-bottom-left-radius: 1rem;
        overflow: hidden;
        
    }

    & li:last-of-type {
        overflow: hidden;
        
        //border-top-right-radius: 1rem;
        //border-bottom-right-radius: 1rem;

    }
`

const Btn = styled.button<any>`
    cursor: pointer;
    padding: .4rem;
    border: none;
    background: var(--table-row-even-bg-color);
    background: transparent;
    font-weight: ${props => props.$active ? 'bold' : 'normal'};
    color: ${props => props.$active ? 'var(--table-action-color)' : 'var(--table-row-text-color)'};
    width: 3em;
    
    &:hover {
        filter: brightness(.85);
    }
`
