import {FieldMap, FieldOperations, OpsCategories, SqlOperation, TableI} from "../../Types";
import moment from "moment";
import {nanoid} from "@reduxjs/toolkit";


let fieldOperations: FieldOperations = {
    id: [...OpsCategories.eq, ...OpsCategories.range, ...OpsCategories.in,],
    dt: [...OpsCategories.range],
    msisdn: [...OpsCategories.eq, ...OpsCategories.like, ...OpsCategories.in,],
    name: [...OpsCategories.like],
    prize_id: [...OpsCategories.in],
    rate: [...OpsCategories.range],
    orders: [...OpsCategories.range],
    deleted: [...OpsCategories.eq],
};

export function orders287DefFilters() {
    return [
        {
            nanoid: nanoid(),
            field: 'dt',
            type: 'datetime',
            filterOp: SqlOperation.GREATER_THAN_OR_EQUAL,
            locked: true,
            value: moment().subtract(7, 'days').startOf('day').format('yyyy-MM-DD HH:mm:ss')
        },
        {
            nanoid: nanoid(),
            field: 'dt',
            type: 'datetime',
            filterOp: SqlOperation.LESS_THAN_OR_EQUAL,
            locked: true,
            value: moment().endOf('day').locale('en').format('yyyy-MM-DD HH:mm:ss')
        },
        {
            nanoid: nanoid(),
            field: 'msisdn',
            type: 'tel',
            filterOp: SqlOperation.LIKE,
            locked: true,
            value: ''
        }
    ]
}

export const OrdersTable: TableI = {
    backend: "/287/",
    title: {
        ua: "Замовлення",
        en: "Замовлення",
    },
    pk: 'id',
    defaultOrderedBy: "id",
    defaultOrderedWay: "DESC",
    defaultPerPage: 20,
    orderByFields: {
        "id": {ua: "ID", en: "№"},
        "dt": {ua: "Створено", ru: "Дата"},
        "rate": {ua: "Бали", ru: "Уровень"},
        "orders": {ua: "Замовлень", ru: "Подтвержденый"},
    },
    fieldOperations: fieldOperations,
    defaultFilters: 'orders287DefFilters',
    fields: [
        {
            key: "n",
            inline: true,
            title: {ua: "№", ru: "№"}, type: "number",
            applyTransform: true
        }, {
            key: "id",
            detailed: true,
            title: {ua: "ID", ru: "№"},
            type: "number",
            applyTransform: true
        },
        {
            key: "dt",
            title: {ua: "Дата", ru: "Дата"},
            type: "datetime",
            datetimeFormat: "DD MMMM YYYY HH:mm",
            applyTransform: true,
            detailed: true,
            inline: true,
        },
        {
            key: "msisdn", type: 'tel',
            applyTransform: true,
            detailed: true, inline: true, title: {ua: "Телефон", ru: "Телефон"}
        },
        {key: "user_name", type: 'string', inline: true, title: {ua: "Користувач", ru: "Пользователь"}},
        {
            key: "prize_id",
            title: {ua: "Подарунок", ru: "Уровень"},
            detailed: true,
            inline: true,
            // editable: true,
            applyTransform: true,
            type: "map",
            map: {
                async: 'all_prizes'
            } as FieldMap,
        },
        {key: "rate", type: "number", title: {ua: "Балів", ru: "Балів"}, detailed: true, inline: true},
        {key: "orders", title: {ua: "Заказів", ru: "Заказов"}, type: "number", inline: true},
        {
            key: "comment",
            title: {ua: "Коментар", ru: "Комментарий"},
            detailed: true,
            editable: true,
            type: 'string'
        },
        {
            key: "deleted",
            title: {ua: "Видалений", ru: "Комментарий"},
            detailed: true,
            editable: true,
            applyTransform: true,
            type: 'boolean'
        },
    ]
}
