import styled from "styled-components";
import {FilterInputI, SqlOperation, SqlOperationInfo} from "../../Types";
import {default as Select, Options} from 'react-select'
import React, {useMemo} from "react";
import {FilterSchema} from "../../287/tables/UsersTable";
import {useAppDispatch} from "../../hooks";
import {deleteFilter, selectFilterField, selectFilterOperation} from "../../features/tables/tableSlice";
import FilterValue from "./FilterValue";
import TypeIcon from "../misc/TypeIcon";
import {faXmark} from "@fortawesome/free-solid-svg-icons";
import Icon from "../misc/Icon";

interface SimpleFilterI {
    index: number;
    filter: Partial<FilterInputI>,
    allFields: Options<any>
    filtersSchema: { [key: string]: FilterSchema },
    nanoId?: string
}

function SimpleFilter({filter, index, allFields, filtersSchema}: SimpleFilterI) {

    const availableOps = useMemo(() => {
        return filter.field ? filtersSchema[filter.field].operations.map(op => ({
            label: SqlOperationInfo[op].ua,
            value: op
        })) : []
    }, [filter.field, filtersSchema]);


    let dispatch = useAppDispatch();

    let basis = 18
    let grow = true
    let min = 150 as number | undefined
    let max = 450 as number | undefined

    if (filter.field) {
        switch (filtersSchema[filter.field].type) {
            case "boolean":
                basis = 21;
                grow = false
                break;
            case "string":
                basis = 16
                grow = true
                min = 350
                max = 550
                break;
            case "email":
                basis = 28
                grow = false
                min = 250
                max = 650
                break;
            case "tel":
                basis = 28
                grow = false
                max = 450
                break;
            case "number":
                basis = 22
                grow = false
                break;
            case "datetime":
                basis = 26
                grow = false
                break;
            case "date":
                basis = 20
                grow = false
                break;
            case "time":
                basis = 16
                grow = false
                break;
            case "map":
                basis = 36
                grow = false
                max = 800
                break;
            default:
                break
        }
    }


    let nullOP = filter.filterOp === SqlOperation.IS_NOT_NULL || filter.filterOp === SqlOperation.IS_NULL;

    if (filter.filterOp === SqlOperation.BETWEEN) {
        basis = basis + 3
        max = max! + 20
    }

    if (filter.filterOp === SqlOperation.IN || filter.filterOp === SqlOperation.NOT_IN) {
        basis = basis * 1.8
        max = max! * 2
    }

    if (nullOP) {
        basis = 1
        grow = false
        min = undefined
    }

    return <>
        {!filter.locked && <Container $basis={20} $grow={false} $select>
            <Select
                onChange={(newValue) => {
                    dispatch(selectFilterField({
                        index,
                        field: newValue!.value,
                        fieldType: filtersSchema[newValue!.value].type
                    }));
                }} className={'select'} placeholder={"Поле"} options={allFields}
                value={filter.field ? {
                    label: filtersSchema[filter.field].title.ua,
                    value: filter.field
                } : null}/>
            <Select
                className={'select'}
                onChange={(newValue) => dispatch(selectFilterOperation({
                    index,
                    op: newValue!.value,
                    type: filtersSchema[filter.field!].type
                }))}
                isDisabled={!filter.field || filter.locked}
                placeholder={"умова"}
                value={filter.filterOp ? {label: SqlOperationInfo[filter.filterOp].ua, value: filter.filterOp} : null}
                options={availableOps}/>
            <RemoveFilterButton onClick={() => dispatch(deleteFilter(index))}>
                <button>
                    <Icon title={'Видалити фільтр'} src={'/win-icons/Mail stamp (cross).ico'}
                          component={faXmark}
                          width={'1rem'}
                          height={'1rem'}
                          className={'fa-color-red'}
                          alt={"delete filter"}/>
                </button>
            </RemoveFilterButton>
        </Container>}
        {filter.locked && <Container $basis={basis} $grow={grow && !nullOP} $min={min} $max={max}>
            <View $noValue={nullOP}>
                <div className={'field'}>
                    <TypeIcon className={'title-icon'} type={filtersSchema[filter.field!].type}/>
                    <span title={filtersSchema[filter.field!].title.ua}
                          className={'name'}>{filtersSchema[filter.field!].title.ua}</span>
                    <span className={'divider'}>&nbsp;|&nbsp;</span>
                    {nullOP ?
                        <span className={'null'}>
                        {SqlOperationInfo[filter.filterOp!].ua}
                    </span> : <span className={'op'}>{SqlOperationInfo[filter.filterOp!].ua}</span>}
                </div>
                {!nullOP && filter.filterOp! !== SqlOperation.BETWEEN && <div className={'value'}>
                    <FilterValue filterSchema={filtersSchema[filter.field!]}
                                 index={index}
                                 value={filter.value}
                                 op={filter.filterOp!}/>
                </div>}
                {filter.filterOp === SqlOperation.BETWEEN && <div className={'between-value'}>
                    <FilterValue filterSchema={filtersSchema[filter.field!]}
                                 index={index}
                                 value={filter.value}
                                 op={filter.filterOp!} arrPos={0}/>
                    <div>та</div>
                    <FilterValue filterSchema={filtersSchema[filter.field!]}
                                 index={index}
                                 value={filter.value}
                                 op={filter.filterOp!} arrPos={1}/>
                </div>}
                <RemoveFilterButton onClick={() => dispatch(deleteFilter(index))}>
                    <button>
                        <Icon title={'Видалити фільтр'} src={'/win-icons/Mail stamp (cross).ico'}
                              component={faXmark}
                              width={'1rem'}
                              height={'1rem'}
                              className={'fa-color-red'}
                              alt={"delete filter"}/>
                        {/*<img src="/win-icons/Mail stamp (cross).ico" alt="delete filter"/>*/}
                    </button>
                </RemoveFilterButton>
            </View>
        </Container>}

    </>
}

export default SimpleFilter

const RemoveFilterButton = styled.div`
    border: none;
    display: flex;
    align-items: stretch;
    //width: 2rem;
    appearance: none;

    & button {
        width: 100%;
        padding: .2rem .2rem;
        background: transparent;
        border: none;
        cursor: pointer;
        display: flex;
        align-items: center;

        & .fa-color-red {
            path {
                fill: red;
                stroke: red;
            }
        }

        & img {
            height: 1.5rem;
            width: 1.5rem;
            transform: scaleX(-1);
        }
    }
`


const View = styled.div<any>`
    border: 2px solid var(--table-action-color);
    border-color: var(--table-border-color);
    border-radius: .2rem;
    padding-right: 0;
    display: flex;
    background: var(--table-row-odd-bg-color);
    color: var(--table-row-text-color);
    width: ${props => props.$noValue ? 'unset' : '100%'};
    //overflow: hidden;
    //height: 38px;

    @media (max-width: 500px) {
        flex-wrap: wrap;
        flex-direction: row;

        & ${RemoveFilterButton} {
            order: 2
        }
    }

    & .value {
        white-space: nowrap;
        width: 100%;
        @media (max-width: 500px) {
            order: 3;
        }
    }

    & .between-value {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        gap: .1rem;
        width: 100%;
        @media (max-width: 500px) {
            order: 3;
        }
    }

    & .field {
        padding: .4rem;
        display: flex;
        align-items: center;
        white-space: nowrap;

        @media (max-width: 500px) {
            flex-grow: 1;
            justify-content: center;
            order: 1;
        }
        ${props => props.$noValue ? null : 'border-right: 2px solid var(--table-action-color);'}
        border-right-color: var(--table-border-color);
        
        & .name {
            font-weight: bold;
            max-width: 120px;
            white-space: nowrap; /* Prevent the text from wrapping to a new line */
            overflow: hidden; /* Hide the text that overflows the container */
            text-overflow: ellipsis; /* Add ellipsis (...) at the end of the overflowing text */
        }

        & .op {
            font-style: italic;
        }

        & .null {
            font-style: italic;
            //color: var(--table-action-color);
            text-decoration: underline var(--table-action-color);
        }

        & .divider {
            color: var(--table-action-color);
        }
    }

    & .title-icon {
        margin-right: .2rem;
        height: 1.2em;
        width: 1.2em;
    }


`

const Container = styled.div<any>`
    min-height: 42px;
    display: flex;
    align-items: center;
    //gap: .2rem;
    //max-width: max(500px, min(500px, 50%));
    height: 100%;


    ${props => props.$select ? 'min-width: ' + props.$min + 'px;' : null} //border: ${props => props.$select ? '2px solid var(--table-action-color)' : 'unset'};
    border-radius: .2rem;
    background: ${props => props.$select ? 'var(--table-row-odd-bg-color)' : 'unset'};;

    & ${RemoveFilterButton} {
        border: ${props => props.$select ? '2px solid var(--table-action-color)' : 'unset'};
        border-top-right-radius: ${props => props.$select ? '.2rem' : '0'};
        border-bottom-right-radius: ${props => props.$select ? '.2rem' : '0'};
        border-left: ${props => !props.$select ? '2px solid var(--table-action-color)' : 'unset'};
        border-color: var(--table-border-color);
        min-height: ${props => props.$select ? '38px' : 'unset'};

        @media (max-width: 500px) {
            border: none;
            margin: 0;
        }
    }

    flex-grow: ${props => props.$grow ? '1' : '0'};
    flex-shrink: ${props => props.$grow ? '1' : '0'};;
    // min-width: 350px;
    // max-width: 100%;
    //
        // ${props => props.$min ? 'min-width: ' + props.$min + 'px;' : null}
    //
    flex-basis: 100%;
    @media (min-width: 720px) {
        //min-width: unset;
        ${props => props.$min ? 'min-width: ' + props.$min + 'px;' : 'min-width: unset;'}
        flex-basis: ${props => props.$basis ? props.$basis + 'rem' : 'min-content'};
        max-width: min(calc(100vw - 4rem), ${props => props.$max ? props.$max + 'px' : props.$basis + 18 + 'rem'});
    }

    & .fake-input {
        //width: 100%;
    }

    & .select {
        flex-grow: 1;
    }

`
