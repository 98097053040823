import styled from "styled-components";
import React, {useState} from "react";
import moment from "moment";
import {schemas} from "../../config";
import Loader from "../../components/Loader";
import {LoaderContainer} from "../../components/Table";
import {toast} from "react-toastify";
import {useAppDispatch} from "../../hooks";
import {fetchTableByQuery} from "../../features/tables/tableSlice";

let initialState = {
    title: '',
    description: '',
    start_timestamp: moment().locale('en').format('yyyy-MM-DDTHH:mm'),
    end_timestamp: moment().locale('en').format('yyyy-MM-DDTHH:mm'),
    presenter: '',
    video_url: '',
    program_url: '',
    max_regs: 24
};

function CreateSeminar() {
    const [seminar, setSeminar] = useState<{ [key: string]: any }>(initialState)
    const [fetching, setFetching] = useState('idle')

    const changeValue = (name: string, value: string, type = 'string') => {
        let newValue: string | number = value;
        if (type === 'number' && value !== '') {
            const parsedValue = parseFloat(value);
            newValue = (isNaN(parsedValue) ? 0 : parsedValue);
        }
        setSeminar(state => ({
            ...state,
            [name]: newValue
        }))
    }

    const dispatch = useAppDispatch();

    const createSeminar = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()

        let init = {
            method: "POST",
            body: JSON.stringify(seminar)
        };

        setFetching('loading')
        fetch(schemas['act287'].backend + '/287/createSeminar.php', init)
            .then(r => r.json())
            .then(r => {
                if (r.status === 'success') {
                    toast.success(r.message)
                    setSeminar(initialState)
                    dispatch(fetchTableByQuery({clean: true, pageIndex: 1}))
                } else
                    toast.error(r.message)
                setFetching('idle')
            })
            .catch(e => {
                toast.error(e)
                setFetching('idle')
            })
    }

    const renderInputs = () =>
        schema.map(({name, type, label, required}) => (
            <div key={name} className="form-field">
                <label htmlFor={name}>
                    {label} {required ? '*' : ''}
                </label>
                <input
                    id={name}
                    name={name}
                    type={type}
                    placeholder={label}
                    value={seminar[name]}
                    onChange={(e) => changeValue(name, e.currentTarget.value, type)}
                    required={required}
                />
            </div>
        ));


    return <Container>
        <h3>Створити новий семінар</h3>
        <form onSubmit={e => createSeminar(e)}>
            <fieldset>
                <legend>Новий семінар</legend>
                {renderInputs()}
                <div className={'create-btn'}>
                    <button type={'submit'} disabled={fetching === 'loading'}>Створити</button>
                </div>
            </fieldset>
        </form>
        {fetching === 'loading' && <LoaderContainer>
            <Loader/>
        </LoaderContainer>}
    </Container>
}

export default CreateSeminar

const Container = styled.section`
    margin-top: .5rem;
    margin-left: auto;
    margin-right: auto;
    color: var(--table-row-text-color);
    display: flex;
    flex-flow: column;
    gap: .5rem;
    max-width: 800px;
    padding: .5rem;

    position: relative;

    & h3 {
        text-align: center;
        font-size: 1.6rem;
        font-weight: bold;
    }

    & fieldset {
        margin-inline: 2px;
        padding-block: 0.35em 0.625em;
        padding-inline: 0.75em;
        border: 2px groove ThreeDFace;
        min-inline-size: min-content;
        display: flex;
        flex-flow: column;

        & legend {
            background-color: #000;
            color: #fff;
            padding: 3px 6px;
        }

        & .create-btn {
            display: flex;
            justify-content: center;
            margin-top: .5rem;

            & button {
                max-width: 300px;
                width: 100%;
                padding: .4rem;
                border-radius: .2rem;
                background-color: var(--table-action-color);
                cursor: pointer;
                color: var(--table-row-text-color);
                border: none;
                font-size: 1rem;
                font-weight: bold;

                &:hover {
                    filter: brightness(.85);
                }
            }
        }

    }

    & .form-field {

        display: flex;
        flex-wrap: wrap;
        gap: .5rem;
        font-size: 1rem;
        font-weight: bold;
        align-items: center;
        padding: .5rem 0;
        border-bottom: 2px solid var(--table-border-color);

        & label {
            flex-basis: 150px;
        }

        & input {
            flex-grow: 1;
            min-width: 280px;
            padding: .2rem;
            font-size: 1rem;

            &[type='datetime-local'] {
                flex-grow: 0;
                width: 230px;
                min-width: 230px;
            }

        }
    }



`

const schema = [
    {
        name: 'title',
        type: 'text',
        label: 'Назва:',
        required: true,
    },
    {
        name: 'description',
        type: 'text',
        label: 'Опис:',
        required: false,
    },
    {
        name: 'start_timestamp',
        type: 'datetime-local',
        label: 'Початок:',
        required: true,
    },
    {
        name: 'end_timestamp',
        type: 'datetime-local',
        label: 'Кінець:',
        required: true,
    },
    {
        name: 'presenter',
        type: 'text',
        label: 'Доповідач:',
        required: true,
    },
    {
        name: 'video_url',
        type: 'text',
        label: 'URL відео:',
        required: false,
    },
    {
        name: 'program_url',
        type: 'text',
        label: 'URL програми:',
        required: false,
    },
    {
        name: 'max_regs',
        type: 'number',
        label: 'Макс. кількість реєстрацій:',
        required: true,
        defaultValue: 24,
    },
];
