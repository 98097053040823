import React, {useState} from 'react';
import {Field} from '../../Types';
import UpdateFieldButton from "../UpdateFieldButton";
import styled from "styled-components";
import moment from "moment/moment";
import {useAppDispatch} from "../../hooks";
import {updateSingleTableField} from "../../features/tables/tableSlice";

function EditDatetime({value, schema, pk, closeEditing}: {
    value: string | number,
    schema: Field,
    pk: string | number,
    closeEditing: () => void
}) {

    let [isChanged, setIsChanged] = useState(false);
    let [newValue, setNewValue] = useState<string>((() => {
        if (schema.type === 'datetime')
            return moment(value).locale('en').format('yyyy-MM-DDTHH:mm');
        if (schema.type === 'date')
            return moment(value).locale('en').format('yyyy-MM-DD');
        if (schema.type === 'time')
            return moment(value, [moment.ISO_8601, schema.datetimeFormat ?? 'HH:mm'])
                .format("HH:mm");
        return ''
    })());

    function onChange(e: React.ChangeEvent<HTMLInputElement>) {
        let val = e.currentTarget.value;
        setNewValue(val);
        val !== value ? setIsChanged(true) : setIsChanged(false)
    }

    const dispatch = useAppDispatch();

    function updateField() {
        dispatch(updateSingleTableField({field: schema.key, value: newValue, id: pk}))
        closeEditing()
    }


    return <Container>
        <div className="wrapper">
            <UpdateFieldButton disabled={!isChanged} onClick={updateField}/>
            <input type={schema.type === 'datetime' ? 'datetime-local' : schema.type} className="edit-input-datetime"
                   value={newValue}
                   onChange={(e) => onChange(e)}/>
        </div>
    </Container>
}

export default EditDatetime;

const Container = styled.div`

    & .wrapper {
        display: flex;
        gap: .5rem;
        justify-content: start;
    }

    & input {
        //font-size: 1rem;
    }


`

