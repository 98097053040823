import {headerTypesIcons, headerTypesIconsFontAwesome} from "../../config";
import React from "react";
import {FieldType} from "../../Types";
import {useAppSelector} from "../../hooks";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

function Win95Icon({className, type}: { className: string, type: FieldType }) {

    return <>
        {headerTypesIcons[type] && <img
            className={className}
            title={headerTypesIcons[type].alt}
            src={headerTypesIcons[type].url}
            alt={headerTypesIcons[type].alt}/>
        }
    </>
}

export function FontAwesomeTypeIcon({className, type}: { className: string, type: FieldType }) {

    return <>
        <FontAwesomeIcon title={headerTypesIcons[type].alt} icon={headerTypesIconsFontAwesome[type].component}
                         className={'fa-color ' + className}/>
    </>
}


export default function ({className = '', type}: { className: string, type?: FieldType }) {

    let iconsStyle = useAppSelector(state => state.project.iconsStyle);

    if (type !== undefined) {
        if (iconsStyle === 'win95')
            return <Win95Icon className={className} type={type}/>

        if (iconsStyle === 'fontAwesome')
            return <FontAwesomeTypeIcon className={className} type={type}/>
    }

    return <>
        No icon for this style
    </>
}
