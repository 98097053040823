import styled from "styled-components";
import React, {useEffect, useState} from "react";
import {schemas} from "../config";
import {OverlayContainer} from "./misc/ImageViewer";
import Settings from "./settings/Settings";
import Icon from "./misc/Icon";
import {faGear, faRightToBracket} from "@fortawesome/free-solid-svg-icons";


const i = {
    actId: 287,
    projectName: 'act287',
    actName: 'Ariston 2019',
    actStart: '2019-10-02',
    actEnd: '2019-10-02',
    userName: 'Roman'
}

export default function ({project}: { project: string }) {

    const [data, setData] = useState<any>(undefined)
    const [isSettingsOpen, setIsSettingsOpen] = useState<boolean>(false)

    useEffect(() => {
        if (!data) {
            let item = localStorage.getItem('project');
            if (item) {
                setData(JSON.parse(item))
            } else {
                window.location.href = '/login.html'
            }
        }
    }, [data]);

    function logout() {
        fetch(schemas[project].backend + '/logout.php', {credentials: 'include'})
            .then(() => {
                localStorage.removeItem('project')
                setData(undefined)
            })
    }

    return <Container>
        <div className={'logo'}>
            <img src="/icon.png" alt="icon"/>
        </div>
        {data && <>
            <div className="act">
                <div>LEAD9 ENGINE</div>
                <h1>{data.actName}</h1>
                <small>№{data.actId}, {data.actStart} - {!!data.actEnd && data.actEnd}</small>
            </div>
            <div className="user">
                <div className="name">
                    {data.user}
                </div>
                <div className="buttons">
                    <button className="setting" onClick={() => setIsSettingsOpen(!isSettingsOpen)}>
                        <Icon src={"/win-icons/Settings.ico"}
                              title={'Налаштування'} alt={'Налаштування'}
                              width={'1.2rem'}
                              height={'1.2rem'}
                              component={faGear}
                        />
                    </button>
                    <button className="logout" onClick={logout}>
                        <Icon src={"/win-icons/Exit%20(gray).ICO"}
                              title={'Вийти'} alt={'Вийти'}
                              width={'1.2rem'}
                              height={'1.2rem'}
                              component={faRightToBracket}
                        />
                    </button>
                </div>
            </div>
        </>}
        {isSettingsOpen && <OverlayContainer onClick={() => setIsSettingsOpen(false)} $isVisible={isSettingsOpen}>
            <Settings close={() => setIsSettingsOpen(false)}/>
        </OverlayContainer>}
    </Container>
}

const Container = styled.header`
    height: 4.5rem;
    background-color: var(--table-row-odd-bg-color);
    background-color: var(--secondary);
    display: flex;
    gap: .5rem;
    color: var(--secondary-text);
    //color: var(--table-row-odd-bg-color);

    padding-left: 1rem;
    padding-right: 1rem;

    @media (max-width: 500px) {
        padding-left: .4rem;
        padding-right: .4rem;

    }

    & .act {
        padding-top: .5rem;
        flex-shrink: 1;
        min-width: 50px;

        & h1 {
            font-size: 1.2rem;
            font-weight: bold;
        }

        & small {
            font-size: 13px;
            white-space: nowrap;
        }

    }

    & .user {
        display: flex;
        gap: .5rem;
        flex-flow: column;
        align-items: center;
        font-weight: bold;
        font-size: 1.1rem;
        margin-left: auto;
        padding-top: .5rem;

        & .buttons {
            display: flex;
            gap: .5rem;
            align-items: center;

            & button {
                background: var(--table-row-odd-bg-color);
                padding: .2rem;
                cursor: pointer;
                border-radius: .2rem;
                display: flex;
                align-items: center;
                border: none;

                &:hover {
                    filter: brightness(.85);
                }
            }

            & img {
                width: 1.5rem;
                height: 1.5rem;
            }
        }


    }

    & .logo {
        display: flex;
        align-items: center;
        height: 100%;

        img {
            width: 50px;
            height: 50px;
        }
    }
`
