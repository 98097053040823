import {createSlice, PayloadAction} from "@reduxjs/toolkit";

export type PageI = {
    custom?: boolean;
    name: string
    lang: { [key: string]: string }
    func?: string
    disabled?: boolean
};

interface ProjectI {
    name: string;
    activePage: number
    pages: PageI[];
    iconsStyle: string
}

const initialState: ProjectI = {
    name: 'act287',
    iconsStyle: 'fontAwesome',
    //iconsStyle: 'win95',
    activePage: 0,
    pages: [{
        name: 'table',
        lang: {
            ua: 'Таблиці'
        }
    }, {
        name: 'summary',
        lang: {
            ua: 'Сумарно'
        },
        func: 'Act287SummaryPage'
    },
    //     {
    //     name: 'graph',
    //     lang: {
    //         ua: 'Графи'
    //     },
    //     disabled: true
    // }
    ],
}
export const projectSlice = createSlice({
    name: 'project',
    initialState,
    reducers: {
        setActivePage(state, action: PayloadAction<number>) {
            state.activePage = action.payload
        },
        setIconsStyle(state, action: PayloadAction<string>) {
            state.iconsStyle = action.payload
        }
    },
})

// Action creators are generated for each case reducer function
export const {setActivePage, setIconsStyle} = projectSlice.actions

export default projectSlice.reducer
