import styled from "styled-components";
import ThemeChanger from "./ThemeChanger";


export default function ({close}: { close: () => void }) {


    return <Container onClick={(e) => e.stopPropagation()}>
        <h3 className="header">Налаштування</h3>
        <div className="main">
            <ThemeChanger/>
        </div>
        <div className="controls">
            <button onClick={close}>
                ❌
            </button>
        </div>
    </Container>
}

const Container = styled.section`
    max-width: min(500px, 85vw);
    max-height: 80vh;
    width: 100%;
    color: var(--table-row-text-color);
    padding: .5rem;
    border-radius: .5rem;
    background-color: var(--table-row-odd-bg-color);

    display: flex;
    gap: .5rem;
    flex-flow: column;

    & .header {
        font-size: 1.1rem;
        font-weight: bold;
        text-align: center;
    }


    & .controls {
        display: flex;
        justify-content: end;
        align-items: center;

        & button {
            background: var(--table-action-color);
            padding: .2rem;
            cursor: pointer;
            border-radius: .2rem;
            display: flex;
            vertical-align: center;
            align-items: center;
            justify-content: center;
            font-size: 1rem;
            border: none;

            &:hover {
                filter: brightness(.85);
            }
        }
    }
`
