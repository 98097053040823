import React from 'react';
import styled from "styled-components";
import {toast} from "react-toastify";
import Icon from "./Icon";
import {faCopy} from "@fortawesome/free-solid-svg-icons";

function CopyButton({copyValue}: { copyValue: any }) {

    function onClick(e: React.MouseEvent) {
        e.stopPropagation()
        navigator.clipboard.writeText(copyValue).then(() => {
            toast.success(`Значення ${copyValue} скопійовано до буфера обміну.`)
        });
    }

    return (
        <Button onClick={e => onClick(e)}>
            {/*<Copy width={"1em"} height={"1em"}/>*/}
            <Icon title={'Копіювати'} src={'/win-icons/Blank%20sheets.ico'}
                  component={faCopy}
                  width={'1rem'}
                  height={'1rem'}
                  className={'fa-color'}
                  alt={"Копіювати"}/>
        </Button>
    )
}

export default CopyButton;

const Button = styled.button`
    cursor: pointer;
    display: inline;
    justify-content: center;
    align-items: center;
    border-radius: .2em;
    width: 1.2rem;
    height: 1.2rem;
    padding: 0;
    line-height: 1;
    font-size: 1rem;
    background: transparent;
    border: none;

    & img {
        width: 1rem;
        height: 1rem;
    }

    & svg {
        stroke: var(--table-action-color);
        fill: var(--table-action-color);
    }

    &[disabled] {
        filter: grayscale(.5);
    }

    &:hover {
        filter: brightness(.85);
    }
`
