import React from 'react';
import {Field} from "../Types";
import EditStringNumber from "./editFields/EditStringNumber";
import EditDatetime from "./editFields/EditDatetime";
import EditBoolean from "./editFields/EditBoolean";
import EditSelectMap from "./editFields/EditSelectMap";

function EditField({value, schema, pk,closeEditing}: { value: string | number | boolean, schema: Field, pk: string | number, closeEditing: ()=>void }) {

    let element;

    switch (schema.type) {
        case "string":
        case "number":
        case "url":
        case "tel":
        case "image":
        case "email":
            element = <EditStringNumber value={(value) as string | number} schema={schema} pk={pk} closeEditing={closeEditing}/>;
            break;
        case "map":
            // element = <EditMap value={value as string} schema={schema}/>
            element = <EditSelectMap value={value as string} schema={schema} pk={pk} closeEditing={closeEditing}/>
            break;
        case "datetime":
        case "date":
        case "time":
            element = <EditDatetime value={value as string} schema={schema} pk={pk} closeEditing={closeEditing}/>
            break;
        case "boolean":
            element = <EditBoolean value={value as boolean} schema={schema} pk={pk} closeEditing={closeEditing}/>
            break;
        default:
            element = <div>Unsupported type</div>;
    }

    return element;
}

export default EditField;
