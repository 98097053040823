import {default as Select, StylesConfig} from "react-select";
import React, {useMemo, useState} from "react";
import {schemas} from "../../config";
import {useAppDispatch, useAppSelector} from "../../hooks";
import {OptionI, PjTa} from "../../Types";
import {changeOrderWay, setOrderBy} from "../../features/tables/tableSlice";
import styled from "styled-components";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowDownWideShort, faArrowUpWideShort} from "@fortawesome/free-solid-svg-icons";

const lang = 'ua'

const selectStyles: StylesConfig<any> = {
    control: (styles) => ({
        ...styles,
        borderLeft: 'none',
        borderBottomLeftRadius: '0',
        borderTopLeftRadius: '0',
        borderWidth: '2px',
        borderColor: 'var(--table-border-color)',
        cursor: 'pointer'
    }),
    menu: (styles) => ({...styles, borderRadius: '0', marginTop: '0', cursor: 'pointer'}),
};

function ini(val: string, tableName: string, projectName: string) {
    if (!val)
        return undefined
    let res = undefined
    Object.entries(schemas[projectName].tables[tableName].orderByFields)
        .forEach(([k, v]) => {
            if (val === k) {
                res = {
                    label: (v as any)[lang],
                    value: k
                };
            }
        })
    return res
}

function TableOrderBy({tableName, projectName}: PjTa) {

    const orderField = useAppSelector(state => state.tables.queries[tableName].current.orderBy)
    let [orderValue, setOrderValue] = useState<any>(ini(orderField, tableName, projectName));
    const options = useMemo(() => {
        let defaultOrderedBy = schemas[projectName].tables[tableName].defaultOrderedBy;
        return Object.entries(schemas[projectName].tables[tableName].orderByFields).map(([k, v]) => {
            let option = {
                label: (v as any)[lang],
                value: k
            };
            if (orderField && orderField === k)
                setOrderValue(option)
            else if (defaultOrderedBy === k)
                setOrderValue(option)
            return option
        })
    }, [orderField, projectName, tableName]);

    const dispatch = useAppDispatch();

    const orderWay = useAppSelector(state => state.tables.queries[tableName].current.orderWay)

    function onChange(newValue: OptionI) {
        setOrderValue(newValue as OptionI)
        dispatch(setOrderBy(newValue.value))
    }

    const changeWay = () => dispatch(changeOrderWay());

    return <Container>
        <button className="order-way" onClick={changeWay}>
            <FontAwesomeIcon title={'sort'} icon={orderWay === 'DESC' ? faArrowDownWideShort : faArrowUpWideShort}
                             className={'fa-color'}/>
            {/*<span>{orderWay === 'DESC' ? '⏬' : '⏫'}</span>*/}
        </button>
        <Select
            className={'select-map'}
            styles={selectStyles}
            value={orderValue}
            onChange={(newValue) => onChange(newValue)}
            closeMenuOnSelect={true}
            options={options}
        />
    </Container>
}


export default TableOrderBy

const Container = styled.div`

    display: flex;
    width: 100%;

    & .order-way {
        border: 2px solid var(--table-border-color);
        border-bottom-left-radius: .2rem;
        border-top-left-radius: .2rem;
        padding: .2rem;
        min-width: 2rem;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        background: var(--table-row-odd-bg-color);
    }

    & .select-map {
        min-width: 14rem;
        width: 100%;
        flex-grow: 1;
        cursor: pointer
    }

`
