import {FieldOperations, OpsCategories, SqlOperation, TableI} from "../../Types";
import {nanoid} from "@reduxjs/toolkit";


let fieldOperations: FieldOperations = {
    dt: [...OpsCategories.range],
    id: [...OpsCategories.eq, ...OpsCategories.range, ...OpsCategories.in,],
    name: [...OpsCategories.like],
    comment: [...OpsCategories.like, ...OpsCategories.null],
    rate: [...OpsCategories.range],
    count: [...OpsCategories.range],
    used: [...OpsCategories.range],
    used_rate: [...OpsCategories.range],
    used_rate_curr_month: [...OpsCategories.range],
    // grouped: [...OpsCategories.eq],
};

export function prizes287DefFilters() {
    return [
        {
            nanoid: nanoid(),
            field: 'name',
            type: 'string',
            filterOp: SqlOperation.LIKE,
            locked: true,
            value: ''
        },
    ]
}

export const PrizesTable: TableI = {
    backend: "/287/",
    title: {
        ua: "Подарунки",
        en: "Замовлення",
    },
    pk: 'id',
    defaultOrderedBy: "id",
    defaultOrderedWay: "DESC",
    defaultPerPage: 20,
    orderByFields: {
        "id": {ua: "ID", en: "№"},
        "dt": {ua: "Створено", ru: "Дата"},
        "rate": {ua: "Ціна", ru: "Дата"},
        "count": {ua: "Кількість", ru: "Дата"},
        "used": {ua: "Замовл.", ru: "Дата"},
        "used_rate": {ua: "Замовл.(балів)", ru: "Дата"},
        "used_rate_curr_month": {ua: "Замовл.(балів)/місяць", ru: "Дата"},
    },
    fieldOperations: fieldOperations,
    defaultFilters: 'prizes287DefFilters',
    fields: [
        {
            key: "n",
            inline: true,
            title: {ua: "№", ru: "№"}, type: "number",
            applyTransform: true
        },
        {
            key: "id",
            inline: true,
            detailed: true,
            title: {ua: "ID", ru: "№"},
            type: "number",
            applyTransform: true
        },
        {
            key: "name",
            title: {ua: "Подарунок", ru: "Комментарий"},
            inline: true,
            detailed: true,
            type: 'string'
        },
        {
            key: "rate",
            title: {ua: "Ціна", ru: "Комментарий"},
            inline: true,
            detailed: true,
            type: 'number',
        },
        {
            key: "count",
            title: {ua: "Кількість", ru: "Комментарий"},
            inline: true,
            detailed: true,
            type: 'number',
        }, {
            key: "used",
            title: {ua: "Замовл.", ru: "Комментарий"},
            inline: true,
            detailed: true,
            type: 'number',
        }, {
            key: "used_rate",
            title: {ua: "Замовл.(балів)", ru: "Комментарий"},
            inline: true,
            detailed: true,
            type: 'number',
        }, {
            key: "used_rate_curr_month",
            title: {ua: "Замовл.(балів)/місяць", ru: "Комментарий"},
            inline: true,
            detailed: true,
            type: 'number',
        }, {
            key: "free",
            title: {ua: "Вільно", ru: "Комментарий"},
            inline: true,
            type: 'number',
            detailed: true,
        },
        {
            key: "dt",
            title: {ua: "Дата", ru: "Дата"},
            type: "datetime",
            datetimeFormat: "DD MMMM YYYY HH:mm",
            applyTransform: true,
            inline: false,
            detailed: true
        },
        {
            key: "comment",
            title: {ua: "Коментар", ru: "Комментарий"},
            inline: false,
            detailed: true,
            type: 'string',
            editable: true
        },
    ]
}
