import {FieldType, SchemaI} from "./Types";
import {act287Functions} from "./287/Act287";
import {
    faArrowDown19,
    faAt,
    faBookAtlas,
    faCalendar,
    faCalendarDays,
    faCheckDouble,
    faClock,
    faFont,
    faImage,
    faLink,
    faMobileRetro
} from "@fortawesome/free-solid-svg-icons";
import {IconProp} from "@fortawesome/fontawesome-svg-core";

export const schemas = {} as { [p: string]: SchemaI }

export const functions = {
    ...act287Functions
} as { [p: string]: (...args: any[]) => any }

export const headerTypesIcons: Record<FieldType, { url: string; alt: string }> = {
    "number": {
        url: "/win-icons/Calculator.ico",
        alt: "Тип даних - чисельний"
    },
    "string": {
        url: "/win-icons/Writing on sheet.ico",
        alt: "Тип даних - текстовий"
    },
    "boolean": {
        url: "/win-icons/IR-Port transfer (Green & Red).ico",
        alt: "Тип даних - булевий"
    },
    "date": {
        url: "/win-icons/Printer calendar.ico",
        alt: "Тип даних - дата"
    },
    "time": {
        url: "/win-icons/Alarm (1 of 8).ico",
        alt: "Тип даних - час"
    },
    "datetime": {
        url: "/win-icons/Book with clock.ico",
        alt: "Тип даних - дата з часом"
    },
    "tel": {
        url: "/win-icons/Red phone and small Yellow phone.ico",
        alt: "Тип даних - телефон"
    },
    "email": {
        url: "/win-icons/Mail.ico",
        alt: "Тип даних - електронна пошта"
    },
    "url": {
        url: "/win-icons/Shortcut (Big).ico",
        alt: "Тип даних - гіперпосилання"
    },
    "map": {
        url: "/win-icons/Book 2.ico",
        alt: "Тип даних - словник"
    },
    "image": {
        url: "/win-icons/Painting.ico",
        alt: "Тип даних - зображення"
    },
    "custom1": {
        url: "",
        alt: "Тип даних - власний 1"
    },
}



export const headerTypesIconsFontAwesome: Record<FieldType, { component: IconProp, alt?: string }> = {
    "number": {
        component: faArrowDown19,
        alt: "Тип даних - чисельний"
    },
    "string": {
        component: faFont,
        alt: "Тип даних - текстовий"
    },
    "boolean": {
        component: faCheckDouble,
        alt: "Тип даних - булевий"
    },
    "date": {
        component: faCalendar,
        alt: "Тип даних - дата"
    },
    "time": {
        component: faClock,
        alt: "Тип даних - час"
    },
    "datetime": {
        component: faCalendarDays,
        alt: "Тип даних - дата з часом"
    },
    "tel": {
        component: faMobileRetro,
        alt: "Тип даних - телефон"
    },
    "email": {
        component: faAt,
        alt: "Тип даних - електронна пошта"
    },
    "url": {
        component: faLink,
        alt: "Тип даних - гіперпосилання"
    },
    "map": {
        component: faBookAtlas,
        alt: "Тип даних - словник"
    },
    "image": {
        component: faImage,
        alt: "Тип даних - зображення"
    },
    "custom1": {
        component: faCheckDouble,
        alt: "Тип даних - власний 1"
    },
}
