import React, {useState} from 'react';
import {Field} from '../../Types';
import UpdateFieldButton from "../UpdateFieldButton";
import styled from "styled-components";
import {getColumnValue} from "../functions";
import {updateSingleTableField} from "../../features/tables/tableSlice";
import {useAppDispatch} from "../../hooks";
import Icon from "../misc/Icon";
import {faArrowRightArrowLeft} from "@fortawesome/free-solid-svg-icons";

function EditBoolean({value, schema, pk, closeEditing}: {
    value: boolean,
    schema: Field,
    pk: string | number,
    closeEditing: () => void
}) {

    let [isChanged, setIsChanged] = useState(false);
    let [newValue, setNewValue] = useState<boolean>(value);

    const onClick = () => {
        !newValue !== value ? setIsChanged(true) : setIsChanged(false)
        setNewValue(!newValue)
    };

    const dispatch = useAppDispatch();

    function updateField() {
        dispatch(updateSingleTableField({field: schema.key, value: newValue, id: pk}))
        closeEditing()
    }


    return <Container>
        <div className="wrapper">
            <UpdateFieldButton disabled={!isChanged} onClick={updateField}/>
            {getColumnValue(newValue, schema)}
            <button className={'change'} onClick={onClick}>
                <Icon src={"/win-icons/IR-Port transfer denied.ico"}
                      title={`Змінити значення на ${newValue ? 'НІ' : 'ТАК'}`} alt={`Змінити значення на ${newValue ? 'НІ' : 'ТАК'}`}
                      width={'1.2rem'}
                      height={'1.2rem'}
                      component={faArrowRightArrowLeft}
                />
            </button>
        </div>
    </Container>
}

export default EditBoolean;

const Container = styled.div`

    & .wrapper {
        display: flex;
        gap: .5rem;
        align-items: center;

        & .change {
            //padding: .2rem;
            background: transparent;
            border: none;
            cursor: pointer;
            display: flex;
            justify-content: center;
            align-items: center;
            &[disabled] {
                filter: grayscale(.5);
            }

            &:hover {
                filter: brightness(.85);
            }
        }

        & img {
            width: 1.5rem;
            height: 1.5rem;
        }
    }


`
