import React, {useEffect} from 'react';
import 'moment/locale/uk';
import {useAppDispatch, useAppSelector} from "../hooks";
import Table from "./Table";
import styled from "styled-components";
import {fetchAsyncMap, fetchTableCounts, setActiveTable} from "../features/tables/tableSlice";
import TableFilter from "./TableFilter";
import TableControls from "./TableControls";
import {functions, schemas} from "../config";


function TablesPage({projectName}: { projectName: string }) {

    let tableName = useAppSelector(state => state.tables.activeTable);
    let asyncMaps = useAppSelector(state => state.tables.tableInfo[tableName].asyncMaps);
    const dispatch = useAppDispatch()
    useEffect(() => {
        if (asyncMaps.loading === 'idle')
            dispatch(fetchAsyncMap({project: projectName, table: tableName, data: asyncMaps.data}))
    }, [asyncMaps.data, asyncMaps.loading, dispatch, projectName, tableName]);

    return (
        <>
            <List tableName={tableName}/>
            <Divider/>
            {asyncMaps.loading === 'loaded' && <>
                <TableFilter tableName={tableName} projectName={projectName}/>
                {/*<Divider/>*/}
                <TableControls projectName={projectName} tableName={tableName}/>
                <Table tableName={tableName} projectName={projectName}/>
            </>}
            {schemas[projectName].tables[tableName].action &&
                functions[schemas[projectName].tables[tableName].action!]()
            }
        </>
    )
}

export default TablesPage;


function List({tableName}: { tableName: string }) {

    let project = useAppSelector(state => state.project.name)
    let names = useAppSelector(state => state.tables.names)
    let count = useAppSelector(state => state.tables.count)

    const dispatch = useAppDispatch();

    useEffect(() => {
        if (!count[tableName]?.total) {
            dispatch(fetchTableCounts(project))
        }
    }, [count, dispatch, project, tableName]);

    return (
        <>
            <UL>
                {Object.entries(names).map(([k, n]) => <li key={k}>
                    <Btn $active={tableName === k} onClick={() => dispatch(setActiveTable(k))}>
                        <span>{n.ua}</span>&nbsp;<small
                        className={'count'}>{count[k].inCondition || '?'} / {count[k].total || '?'}</small>
                    </Btn>
                </li>)}
            </UL>
        </>
    )
}

const H2 = styled.h2`
    color: var(--table-row-text-color);
    font-size: 1.6rem;
    font-weight: bold;
    width: 100%;
    margin-top: .5rem;
    text-align: center;
`

const UL = styled.ul`

    margin-top: .5rem;
    padding: .4rem 0;
    //display: grid;
    //grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    //justify-items: start;
    display: flex;
    gap: .5rem;
    flex-wrap: wrap;

    & > li {
        white-space: nowrap;
        list-style: none;

        @media (max-width: 500px) {
            flex-basis: 100%;

        }
    }



`

const Btn = styled.button<any>`
    appearance: none;
    //background: var(--table-row-odd-bg-color);
    background: transparent;
    padding: .5rem;
    color: var(--table-row-text-color);
    cursor: pointer;
    width: 100%;
    font-size: 1rem;
    border: none;
    border-bottom: 3px solid  ${props => props.$active ? `var(--table-action-color)` : 'var(--table-border-color)'};
    //border-radius: .2rem;
    & .count {
        background: ${props => props.$active ? 'var(--table-action-color)' : 'var(--table-row-odd-bg-color)'};
        color: ${props => !props.$active ? 'var(--table-row-text-color)' : 'var(--table-header-bg-color)' };
        padding: .1rem .2rem;
        border-radius: .2rem;
        font-size: 12px;
        vertical-align: top;
    }


    &:hover {
        filter: brightness(.85);
    }
`
//${props => props.$active && `color: var(--table-action-color);`}


const Divider = styled.hr`
    width: 100%;
    height: 2px;
    background-color: var(--table-action-color);
    margin: 0;
`
