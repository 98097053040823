import React from 'react';
import {Field} from "../Types";


function FakeRow({schema}: { schema: Field[] }) {

    return (
        <tr>
            {schema.map(field =>
                !field.inline ? null : <td key={field.key}>
                    <i><b>Дані....</b></i>
                </td>)}
        </tr>
    );
}

export default FakeRow;

