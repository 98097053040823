import React, {useEffect, useState} from "react";
import styled from "styled-components";


// Array of image sources
const imageSources = [
    '/win-icons/Dial-Up Connection (1 of 4).ico',
    '/win-icons/Dial-Up Connection (2 of 4).ico',
    '/win-icons/Dial-Up Connection (3 of 4).ico',
    '/win-icons/Dial-Up Connection (4 of 4).ico',
];

interface LoaderProps {
    showAfter?: number;
    show?: boolean// Optional prop to set timer delay in ms
}

function Loader({showAfter = 5000, show = true}: LoaderProps) {
    const [currentIndex, setCurrentIndex] = useState(0);
    const [showTimer, setShowTimer] = useState(false);
    const [elapsedTime, setElapsedTime] = useState(0);

    useEffect(() => {
        // Set an interval to change the image every 100ms
        const imageIntervalId = setInterval(() => {
            setCurrentIndex((prevIndex) => (prevIndex + 1) % imageSources.length);
        }, 125);

        // Clear interval on component unmount
        return () => clearInterval(imageIntervalId);
    }, []);

    useEffect(() => {
        const timerStart = Date.now();

        // Update elapsed time every second
        const timeIntervalId = setInterval(() => {
            setElapsedTime(Math.floor((Date.now() - timerStart) / 1000));
        }, 1000);

        // Set a timeout to show the timer display after `showAfter` milliseconds
        const delayTimeoutId = setTimeout(() => {
            setShowTimer(true);
        }, showAfter);

        // Clear both timeout and interval on component unmount
        return () => {
            clearInterval(timeIntervalId);
            clearTimeout(delayTimeoutId);
        };
    }, [showAfter]);

    return (
        <Container>
            <img src={imageSources[currentIndex]} alt="loading"/>
            {show && showTimer && (
                <Timer>Вантажимо вже {elapsedTime} секунд(и)...</Timer>
            )}
        </Container>
    );
}

export default Loader;

const Container = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    gap: .5rem;

    & img {
        height: 100%;
        max-height: 128px;
        width: auto;
    }
`;

const Timer = styled.div`
    color: var(--table-row-text-color);
    background: var(--table-header-bg-color);
    padding: .2rem;
    border-radius: .2rem;
`;
