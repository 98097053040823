import React from "react";
import {FilterSchema} from "./287/tables/UsersTable";

export type Primitive = string | number | boolean | null;
export type PrimitiveObject = {
    [key: string]: Primitive;
};


export type FieldType =
    "number"
    | "string"
    | "boolean"
    | "date"
    | "time"
    | "datetime"
    | "tel"
    | "email"
    | "url"
    | "map"
    | "image"
    | "custom1";

//export type FieldMap = Record<string, Record<string, string | React.JSX.Element>>;

export interface FieldMap {
    groups?: {
        label: { [key: string]: string },
        values: string[]
    }[]
    multiValue?: true
    backgroundColor?: string
    async?: string
    values: {
        [key: string]: {
            rawValue: string | number
            lang: { [key: string]: string }
            color?: string
            icon?: string
        }
    }
}

export interface SelectOption {
    readonly value: string;
    readonly label: { [key: string]: string };
    readonly color?: string;
}

export interface OptionI {
    readonly value: string;
    readonly label: string;
    readonly color?: string;
}

export interface GroupSelectOption {
    label: { [key: string]: string },
    options: SelectOption[]
}

export interface GroupOptionI {
    label: string,
    options: OptionI[]
}

export interface GetColumnValueFuncI {
    (
        value: any,
        schema: Field,
        forHeader?: boolean,
        record?: PrimitiveObject | null
    ): string | React.JSX.Element;
}

export type Field = {
    key: string;
    title: Record<string, string>; // Object with variable keys and string values
    map?: FieldMap; // Object with variable keys and string values
    type: FieldType;
    format?: string; // Used for date fields
    datetimeFormat?: string
    getColumnValue?: string
    detailed?: boolean;
    inlineEditable?: boolean;
    editable?: boolean;
    inline?: boolean;
    required?: boolean;
    filterMulti?: boolean
    applyTransform?: boolean;
    backend?: string; // URL endpoint for backend updates
    defaultVal?: number | string; // Default value for fields
    imageMaxHeight?: number,
    updateSingleFieldPath?: string
};

export enum SqlOperation {
    EQUAL = 'eq',
    NOT_EQUAL = 'neq',
    GREATER_THAN = 'gt',
    LESS_THAN = 'lt',
    GREATER_THAN_OR_EQUAL = 'gte',
    LESS_THAN_OR_EQUAL = 'lte',
    LIKE = 'like',
    IN = 'in',
    NOT_IN = 'not_in',
    IS_NULL = 'is_null',
    IS_NOT_NULL = 'is_not_null',
    BETWEEN = 'between',
}

export const SqlOperationInfo = {
    [SqlOperation.EQUAL]: {
        sql: '=',
        en: 'Equal',
        ua: '='
    },
    [SqlOperation.NOT_EQUAL]: {
        sql: '!=',
        en: 'Not Equal',
        ua: '!='
    },
    [SqlOperation.GREATER_THAN]: {
        sql: '>',
        en: 'Greater Than',
        ua: '>'
    },
    [SqlOperation.LESS_THAN]: {
        sql: '<',
        en: 'Less Than',
        ua: '<'
    },
    [SqlOperation.GREATER_THAN_OR_EQUAL]: {
        sql: '>=',
        en: 'Greater Than or Equal',
        ua: '>='
    },
    [SqlOperation.LESS_THAN_OR_EQUAL]: {
        sql: '<=',
        en: 'Less Than or Equal',
        ua: '<='
    },
    [SqlOperation.LIKE]: {
        sql: 'LIKE',
        en: 'Like',
        ua: 'містить'
    },
    [SqlOperation.IN]: {
        sql: 'IN',
        en: 'In',
        ua: 'будь-який з'
    },
    [SqlOperation.NOT_IN]: {
        sql: 'NOT IN',
        en: 'Not In',
        ua: 'жоден з'
    },
    [SqlOperation.IS_NULL]: {
        sql: 'IS NULL',
        en: 'Is Null',
        ua: 'є порожнім'
    },
    [SqlOperation.IS_NOT_NULL]: {
        sql: 'IS NOT NULL',
        en: 'Is Not Null',
        ua: 'не є порожнім'
    },
    [SqlOperation.BETWEEN]: {
        sql: 'BETWEEN',
        en: 'Between',
        ua: 'між'
    }
};

export const OpsCategories = {
    eq: [SqlOperation.EQUAL, SqlOperation.NOT_EQUAL],
    range: [
        SqlOperation.GREATER_THAN,
        SqlOperation.GREATER_THAN_OR_EQUAL,
        SqlOperation.LESS_THAN,
        SqlOperation.LESS_THAN_OR_EQUAL,
        SqlOperation.BETWEEN,
    ],
    null: [SqlOperation.IS_NULL, SqlOperation.IS_NOT_NULL],
    in: [SqlOperation.IN, SqlOperation.NOT_IN],
    like: [SqlOperation.LIKE],
};

export type FilterValueI =
    string
    | undefined
    | number
    | boolean
    | null
    | Array<string | number>
    | [string | number, string | number];

export interface Filter {
    field: string;
    filterOp: SqlOperation;
    value: FilterValueI;
}

export interface FilterInputI extends Filter {
    type: string,
    locked?: boolean,
    nanoid: string
}

export interface FieldOperations {
    [key: string]: SqlOperation[];
}

export interface PjTa {
    projectName: string,
    tableName: string
}

export interface QueryParamsDTO {
    project: string;
    table: string;
    fields: string[];       // Array of field names
    pages: number;          // Number of pages
    pageIndex: number;      // Current page index
    filters?: Filter[];     // Array of Filter objects
    order?: {
        field: string,
        way: "ASC" | "DESC"
    }; // Object representing column names as keys and order directions as values
}

export interface TableI {
    backend: string;
    title: { [key: string]: string };
    pk: string;
    defaultOrderedBy: string;
    defaultOrderedWay: "DESC" | "ASC"
    defaultPerPage: number
    orderByFields: { [p: string]: { [p: string]: string } };
    fields: Field[];
    fieldOperations?: FieldOperations,
    defaultFilters?: string,
    action?: string
}

export interface SchemaI {
    actName: string;
    backend: string;
    defaultTable: string;
    filterSchemas: { [p: string]: { [p: string]: FilterSchema } };
    tables: { [p: string]: TableI };
}
