import {GetColumnValueFuncI, SchemaI} from "../Types";
import {users287DefFilters, UsersTable} from "./tables/UsersTable";
import {orders287DefFilters, OrdersTable} from "./tables/OrdersTable";
import {ImnsgTable, inmsg287DefFilters} from "./tables/InmsgTable";
import React from "react";
import {photos287DefFilters, PhotosTable} from "./tables/PhotosTable";
import {prizes287DefFilters, PrizesTable} from "./tables/PrizesTable";
import {ordersGrouped287DefFilters, OrdersGroupedTable} from "./tables/OrdersGroupedTable";
import {seminars287DefFilters, SeminarsTable} from "./tables/SeminarsTable";
import {seminarRegs287DefFilters, SeminarRegsTable} from "./tables/SeminarRegsTable";
import CreateSeminar from "./actions/CreateSeminar";
import {webinars287DefFilters, WebinarsTable} from "./tables/WebinarsTable";
import {CertificatesTable} from "./tables/CertificatesTable";
import Act287SummaryPage from "./Act287SummaryPage";


export const act287Schema: SchemaI = {
    filterSchemas: {},
    actName: 'act287',
    backend: process.env.NODE_ENV === 'development' ? "https://192.168.0.106:8443/engine/php" : "/engine/php",
    defaultTable: 'inmsg',
    tables: {
        inmsg: ImnsgTable,
        orders: OrdersTable,
        orders_grouped: OrdersGroupedTable,
        users: UsersTable,
        photos: PhotosTable,
        seminars: SeminarsTable,
        seminar_registrations: SeminarRegsTable,
        prizes: PrizesTable,
        webinars: WebinarsTable,
        certificates: CertificatesTable
    }
}

export const act287Functions = {
    act287getInmsgCodeColumnValue: ((value, schema, forHeader = false, record = null) => {
        if (!!record
            && record['msgtype'] == '4'
            && record['balls'] == '0'
            && record['balls_for_first'] == '0'
            && (record['vac_level'] == '0' || record['vac_level'] === null)) {
            return <React.Fragment>
                {value}
                &nbsp;
                <small className={'yellow-exla'}>❗</small>
            </React.Fragment>
        } else return value
    }) as GetColumnValueFuncI,
    act287createSeminarComponent: () => <CreateSeminar/>,
    Act287SummaryPage: () => <Act287SummaryPage/>,
    inmsg287DefFilters: () => inmsg287DefFilters(),
    orders287DefFilters: () => orders287DefFilters(),
    ordersGrouped287DefFilters: () => ordersGrouped287DefFilters(),
    users287DefFilters: () => users287DefFilters(),
    photos287DefFilters: () => photos287DefFilters(),
    seminars287DefFilters: () => seminars287DefFilters(),
    seminarRegs287DefFilters: () => seminarRegs287DefFilters(),
    prizes287DefFilters: () => prizes287DefFilters(),
    webinars287DefFilters: () => webinars287DefFilters(),
} as { [p: string]: (...args: any[]) => any }
