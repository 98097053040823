import React, {useMemo, useState} from 'react';
import styled from "styled-components";
import {FilterSchema} from "../../287/tables/UsersTable";
import {ActionMeta, default as Select, GroupBase, OptionsOrGroups, StylesConfig} from "react-select";
import chroma from 'chroma-js';
import {useAppDispatch} from "../../hooks";
import {GroupSelectOption, SelectOption} from "../../Types";
import {setFilterValue} from "../../features/tables/tableSlice";


export const selectMapColourStyles: StylesConfig<any, true> = {
    control: (styles) => ({...styles, backgroundColor: '#18111c', borderRadius: '0', borderWidth: '0'}),
    menu: (styles) => ({...styles, backgroundColor: '#18111c', borderRadius: '0', marginTop: '0'}),
    input: (styles) => ({...styles, color: 'white'}),
    singleValue: (styles) => ({...styles, color: 'white'}),
    option: (styles, {data, isDisabled, isFocused, isSelected}) => {
        const color = chroma(data.color || "#fff");
        return {
            ...styles,
            backgroundColor: isDisabled
                ? undefined
                : isSelected
                    ? (data.color || "#fff")
                    : isFocused
                        ? color.alpha(0.1).css()
                        : '#18111c',
            color: isDisabled
                ? '#ccc'
                : isSelected
                    ? chroma.contrast(color, 'white') > 2
                        ? 'white'
                        : 'black'
                    : (data.color || "#fff"),
            cursor: isDisabled ? 'not-allowed' : 'default',

            ':active': {
                ...styles[':active'],
                backgroundColor: !isDisabled
                    ? isSelected
                        ? (data.color || "#fff")
                        : color.alpha(0.3).css()
                    : undefined,
            },
        };
    },
    multiValue: (styles, {data}) => {
        const color = chroma(data.color || "#fff");
        return {
            ...styles,
            backgroundColor: color.alpha(0.1).css(),
        };
    },
    multiValueLabel: (styles, {data}) => ({
        ...styles,
        color: (data.color || "#fff"),
    }),
    multiValueRemove: (styles, {data}) => ({
        ...styles,
        color: (data.color || "#fff"),
        ':hover': {
            backgroundColor: (data.color || "#fff"),
            color: 'white',
        },
    }),
};

const lang = 'ua'

function iniState(schema: FilterSchema, values: any[]) {
    const options = [] as any[]
    values && schema.mapSchema?.options?.forEach(o => {
        if (values.includes(o.value))
            options.push({
                ...o,
                label: o.label[lang],
            })
    });
    return options
}

function FilterMultiMapValue({index, schema, initialValue}: {
    index: number,
    schema: FilterSchema,
    initialValue: any
}) {
    const dispatch = useAppDispatch();
    let [value, setValue] = useState<OptionsOrGroups<SelectOption, GroupBase<SelectOption>>>([...iniState(schema, initialValue)]);

    const options = useMemo(() =>
        ((schema.mapSchema?.groups?.map(o => ({
                label: o.label[lang],
                options: o.options.map(opt => ({...opt, label: opt.label[lang]}))
            })) ||
            schema.mapSchema?.options?.map(o => ({
                ...o,
                label: o.label[lang],
            }))) as OptionsOrGroups<GroupSelectOption, GroupBase<GroupSelectOption>> | undefined), [schema.mapSchema?.groups, schema.mapSchema?.options]);

    function onChange(newValue: any, actionMeta: ActionMeta<unknown>) {
        const value = newValue as SelectOption[]
        setValue(value)
        dispatch(setFilterValue({index, value: value.length ? value.map(x => x.value) : undefined}));
    }

    return <Container>
        <Select
            className={'select-map'}
            value={value}
            onChange={(newValue, actionMeta) => onChange(newValue, actionMeta)}
            closeMenuOnSelect={false}
            isMulti
            noOptionsMessage={() => 'Усі варіанти обрано'}
            placeholder={"Оберіть значення"}
            options={options}
            styles={selectMapColourStyles}
        />
    </Container>
}

export default FilterMultiMapValue;


const Container = styled.div`

    .select-map {
        border-radius: 0;
        color: black;
    }

    & .wrapper {
        display: flex;
        gap: .5rem;
    }


`
