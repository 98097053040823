import React, {useState} from 'react';
import {Field, PrimitiveObject} from "../Types";
import EditField from "./EditField";
import {getColumnValue} from "./functions";
import styled from "styled-components";
import TypeIcon from "./misc/TypeIcon";
import Icon from "./misc/Icon";
import {faLock, faPenToSquare} from "@fortawesome/free-solid-svg-icons";

function Column({value, record, schema, cols, pk}: {
    value: string | number | boolean,
    record: PrimitiveObject
    schema: Field,
    cols: number,
    pk: string | number
}) {

    const [isEditing, setIsEditing] = useState(!!schema.inlineEditable);

    const closeEditing = () => {
        setIsEditing(false)
    }

    return <Container>
        <td className={'edit-title-col'}>
            <div className={'wrapper'}>
                <div className={'title'}>
                    <div className={'main-title'}>
                        <TypeIcon className={'title-icon'} type={schema.type}/>
                        &nbsp;
                        {schema.title.ua}
                    </div>
                </div>
                {schema.editable && <Button onClick={() => setIsEditing(!isEditing)}
                         disabled={!schema.editable}>
                    <Icon src={schema.editable ? "/win-icons/Marking%20forms.ico" : "/win-icons/Document%20Locked.ico"}
                          title={schema.editable ? "Редагувати поле" : "Поле неможливо редагувати"} alt={''}
                          width={'1.2rem'}
                          height={'1.2rem'}
                          component={schema.editable ? faPenToSquare : faLock}
                    />
                </Button>}
            </div>

        </td>
        <td className={'value-col'}>
            {!isEditing && <span onClick={() => setIsEditing(!!schema.editable && !schema.applyTransform && true)}>
                {getColumnValue(value, schema, false, record)}
            </span>
            }
            {isEditing && <EditField value={value} schema={schema} pk={pk} closeEditing={closeEditing}/>}
        </td>

    </Container>
}

export default Column;

const Container = styled.tr<any>`
    max-width: 100vw;


    & > td {

    }

    .value-col {
        width: auto;
    }


    & .edit-title-col {
        text-align: left; /* Aligns text to the left */
        vertical-align: top; /* Vertically centers the content in the cell */
        width: 1%;
        white-space: nowrap;
        //padding-bottom: 1.75rem;


        .wrapper {
            width: 100%;
            display: inline-flex;
            justify-content: left;
            //align-items: center;
        }
    }


    .main-title {
        display: flex;
        align-items: center;
        white-space: nowrap;

        font-weight: bold;
    }

    & .title {
        width: calc(100% - 1.5rem - 1em);
        margin-right: 1em; /* Adds space between the title and the button */
        display: inline-flex; /* Allows the margin to take effect */
        flex-flow: column;
        gap: .2rem;
    }

    & .btn {
        width: 1.5rem;
        height: 1.5rem;
        display: inline-block;
    }

    & .title-icon {
        width: 1rem;
        height: 1rem;
    }


`

const Button = styled.button`
    cursor: pointer;
    vertical-align: middle;
    margin-left: auto;
    padding: 0;
    border: none;
    display: inline-block;
    vertical-align: middle;
    justify-content: center;
    align-items: center;

    background-color: transparent;

    &[disabled] {
        filter: grayscale(.5);
        cursor: not-allowed;
    }

    & img {
        width: 1rem;
        height: 1rem;
    }

    &:hover {
        filter: brightness(.85);
    }

    //background-image: url("/win-icons/Marking forms.ico") ;
    //background-size: contain;
`
