import React, {useEffect, useState} from 'react';
import './App.css';
import moment from "moment";
import 'moment/locale/uk';
import {useAppDispatch, useAppSelector} from "./hooks";
import TablesPage from "./components/TablesPage";
import {functions, schemas} from "./config";
import {createInitialStateForTables, createSchema} from "./features/init";
import {setInitialTableState} from "./features/tables/tableSlice";
import {STATIC_SCHEMAS} from "./staticSchemas";
import styled from "styled-components";
import {PageI, setActivePage, setIconsStyle} from "./features/projectSlice";
import Header from "./components/Header";
import {setCssProp, themes} from "./components/settings/ThemeChanger";

const project = 'act287'

const currentVersion = 3

function App() {

    useEffect(() => {
        moment.locale('uk')
        console.log('moment.js locale is', moment.locale())
    }, [])

    let activePage = useAppSelector(state => state.project.activePage);
    let pages = useAppSelector(state => state.project.pages);
    let projectName = useAppSelector(state => state.project.name);
    const [isStateLoaded, setIsStateLoaded] = useState<boolean>(false)

    const dispatch = useAppDispatch();

    useEffect(() => {
        let version = localStorage.getItem('theme-version');
        if (version === null || Number(version) < currentVersion) {
            localStorage.setItem('theme-version', currentVersion.toString())
            localStorage.removeItem('theme')
            localStorage.removeItem('primary-color')
            localStorage.removeItem('iconStyle')
        }

        let themeKey = localStorage.getItem('theme') || 'classic';
        let color = localStorage.getItem('primary-color') || '#089DF8';
        let icons = localStorage.getItem('iconStyle') || 'fontAwesome';

        if (icons)
            dispatch(setIconsStyle(icons))

        if (themeKey && themes[themeKey]) {
            Object.entries(themes[themeKey].values).forEach(([prop, val]) => {
                setCssProp('--' + prop, val)
            })
        }
        if (color)
            setCssProp('--table-action-color', color)
    }, [dispatch]);

    useEffect(() => {
        const loadSchema = async () => {
            let schema;
            if (process.env.NODE_ENV === 'development') {
                schema = createSchema(STATIC_SCHEMAS[projectName])
            } else
                schema = await fetch(`/schemas/tables/${projectName}.json`).then(r => r.json());

            if (schema) {
                let state = createInitialStateForTables(schema);
                schemas[projectName] = schema
                dispatch(setInitialTableState(state))
            }
        };
        loadSchema().then(_ => setIsStateLoaded(true));
    }, [projectName, dispatch]);


    return (
        <>
            <Header project={projectName}/>
            <main>
                <List pages={pages} active={activePage}/>
                {isStateLoaded && pages[activePage].name === 'table' && <TablesPage projectName={projectName}/>}
                {isStateLoaded && !!pages[activePage].func && functions[pages[activePage].func!]()}
            </main>
        </>
    )
}

export default App;

const lang = 'ua'

function List({pages, active}: { pages: PageI[], active: number }) {
    const dispatch = useAppDispatch();
    return <UL>
        {pages.map((p, i) => <li key={p.name}>
            <Btn $active={i === active} disabled={p.disabled} onClick={() => dispatch(setActivePage(i))}>
                <span>{p.lang[lang]}</span>
            </Btn>
        </li>)}
    </UL>

}

const UL = styled.ul`

    margin-top: .5rem;
    padding: .4rem 0;
    display: flex;
    gap: .5rem;
    flex-wrap: wrap;
    justify-content: center;

    //border-bottom: 2px solid var(--table-action-color);

    & > li {
        white-space: nowrap;
        list-style: none;

        @media (max-width: 500px) {
            flex-basis: 100%;

        }
    }



`

const Btn = styled.button<any>`
    appearance: none;
    background: transparent;
    padding: .2rem .6rem;
    color: var(--table-row-text-color);
    cursor: pointer;
    width: 100%;
    font-size: 1rem;
    border: none;

    & .count {
        background: var(--table-action-color);
        ${props => props.$active && `background: var(--table-row-odd-bg-color);`}
        color: var(--table-row-text-color);
        padding: .1rem .2rem;
        border-radius: .2rem;
        font-size: 10px;
        vertical-align: top;
    }


    ${props => props.$active && `border-bottom: 3px solid var(--table-action-color);`}
    ${props => !props.$active && `border-bottom: 3px solid var(--table-border-color);`}
    &:hover {
        filter: brightness(.85);
    }

    &:disabled {
        filter: grayscale(.6);
    }
`
