import {createAsyncThunk, createSlice, nanoid, PayloadAction} from "@reduxjs/toolkit";
import {
    FieldType,
    Filter,
    FilterInputI,
    FilterValueI,
    Primitive,
    PrimitiveObject,
    QueryParamsDTO,
    SqlOperation
} from "../../Types";
import {schemas} from "../../config";
import {RootState} from "../../store/store";
import {datetimeDefValue} from "../../components/filters/DatetimeFilterValue";
import {createMapFilterSchema} from "../init";
import {toast} from "react-toastify";


export type queryU = "prev" | "current";
export type Query = {
    [K in queryU]: queryI;
};

// const pages = {} as { [key: string]: { [key: string]: PrimitiveObject[] } }
// const tableInfo = {} as { [key: string]: tableInfoI }
// const queries = {} as { [key: string]: Query }
// const names = {} as { [key: string]: { [key: string]: string } }
// const count = {} as { [key: string]: countI }


export interface tableInfoI {
    current_page: number,
    prev_page: number,
    max_pages: number,
    loading: "idle" | "loading" | "error",
    frozen: { [key: string]: boolean | undefined },
    asyncMaps: {
        loading: "idle" | "loading" | "loaded" | "error",
        data: {
            key: string
            queryKey: string
        } []

    }
}


export interface queryI {
    limit: number,
    orderBy: string,
    orderWay: "ASC" | "DESC",
    filters: Partial<FilterInputI>[]
}

export interface countI {
    total: number | undefined;
    inCondition: number | undefined;
}

export interface TableSliceInitialStateI {
    pages: { [p: string]: { [p: string]: PrimitiveObject[] } };
    queries: { [p: string]: Query };
    activeTable: string;
    tableInfo: { [p: string]: tableInfoI };
    names: { [p: string]: { [p: string]: string } };
    count: { [p: string]: countI };
}

type SelectFilterFieldActionPayloadI = {
    index: number,
    field: string,
    fieldType: FieldType
};
export const tablesSlice = createSlice({
    name: 'table',
    initialState: {} as TableSliceInitialStateI,
    reducers: {
        setInitialTableState(state, action) {
            return {
                ...action.payload
            }
        },
        setActiveTable(state, action) {
            state.activeTable = action.payload
        },
        changePage(state, action: PayloadAction<number>) {
            state.tableInfo[state.activeTable].prev_page = state.tableInfo[state.activeTable].current_page
            state.tableInfo[state.activeTable].current_page = action.payload
        },
        changePerPage(state, action: PayloadAction<number>) {
            state.queries[state.activeTable].current.limit = action.payload
        },
        addNewFilter(state, action: PayloadAction<string>) {
            state.queries[action.payload].current.filters.push({
                nanoid: nanoid()
            })
        },
        deleteFilter(state, action: PayloadAction<number>) {
            state.queries[state.activeTable].current.filters.splice(action.payload, 1)
        },
        selectFilterField(state, action: PayloadAction<SelectFilterFieldActionPayloadI>) {
            const {index, field, fieldType} = action.payload;
            state.queries[state.activeTable].current.filters[index].field = field
            state.queries[state.activeTable].current.filters[index].type = fieldType

        },
        selectFilterOperation(state, action: PayloadAction<{ index: number, op: SqlOperation, type: FieldType }>) {
            const {index, op, type} = action.payload;
            let filter = state.queries[state.activeTable].current.filters[index];
            filter.filterOp = op
            filter.locked = true
            let defVal = defaultFilterValue(type);
            if (op === SqlOperation.BETWEEN) {
                filter.value = [defVal, defVal] as any
            } else if (op === SqlOperation.IN || op === SqlOperation.NOT_IN) {
                filter.value = []
            } else if (op === SqlOperation.IS_NOT_NULL || op === SqlOperation.IS_NULL) {
                filter.value = true
            } else {
                filter.value = defVal
            }
        },
        setFilterValue(state, action: PayloadAction<{ index: number, value: FilterValueI, arrPos?: number }>) {
            const {index, value, arrPos} = action.payload;
            let filter = state.queries[state.activeTable].current.filters[index];
            if (arrPos !== undefined) {
                (filter.value as any)[arrPos] = value as string | number
            } else
                filter.value = value
        },
        setOrderBy(state, action: PayloadAction<string>) {
            state.queries[state.activeTable].current.orderBy = action.payload
        },
        changeOrderWay(state, action: PayloadAction<undefined>) {
            state.queries[state.activeTable].current.orderWay = state.queries[state.activeTable].current.orderWay === 'ASC' ? 'DESC' : 'ASC'
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchTableByQuery.pending, (state, action) => {
                state.tableInfo[state.activeTable].loading = "loading";
            })
            .addCase(fetchTableByQuery.fulfilled, (state, action) => {
                const payload = action.payload;
                const query = payload.query;
                const tableName = query.table;
                const pageIndex = query.pageIndex.toString();

                if (payload.result.status === 'error') {
                    state.tableInfo[tableName].loading = "error";
                } else {
                    if (query.clean)
                        state.pages[tableName] = {}
                    state.pages[tableName][pageIndex] = payload.result.data as PrimitiveObject[];
                    state.count[tableName].inCondition = payload.result.count;
                    state.tableInfo[tableName].max_pages = Math.ceil(payload.result.count / query.pages);
                    state.tableInfo[tableName].current_page = query.pageIndex;
                    state.tableInfo[tableName].loading = "idle";
                }
            })
            .addCase(fetchTableByQuery.rejected, (state, action: any) => {
                state.tableInfo[state.activeTable].loading = "error";
                toast.error(action.payload?.result?.message || "Сталась помилка при завантаженні таблиці")
            });

        builder.addCase(fetchTableCounts.fulfilled, (state, action) => {
            action.payload.result.count.map((x: { 'table_name': string; count: number; }) => {
                if (state.count[x['table_name']])
                    state.count[x['table_name']].total = x.count
                return null;
            })
        })

        builder
            .addCase(updateSingleTableField.pending, (state, action) => {
                let activeTable = state.activeTable;
                state.tableInfo[activeTable].frozen[activeTable + action.meta.arg.id] = true
            })
            .addCase(updateSingleTableField.rejected, (state, action) => {
                let activeTable = state.activeTable;
                state.tableInfo[activeTable].frozen[activeTable + action.meta.arg.id] = undefined
                // @ts-ignore
                toast.error(action.payload?.result?.message)
            })
            .addCase(updateSingleTableField.fulfilled, (state, action) => {
                let {result, payload, table, page, pkField} = action.payload;
                state.tableInfo[table].frozen[table + payload.id] = undefined
                if (result.status === 'success') {
                    let find = state.pages[table][page].find(r => r[pkField] === payload.id);
                    if (!find)
                        console.log('item with search pkField ', table + page + payload.id, ' was not found')
                    else {
                        console.log('item with search pkField ', table + page + payload.id, ' WAS found')
                        find[payload.field] = payload.value

                    }
                    toast.success(action.payload?.result?.message)
                }
            })

        builder
            .addCase(fetchAsyncMap.pending, (state, action) => {
                state.tableInfo[action.meta.arg.table].asyncMaps.loading = 'loading'
            })
            .addCase(fetchAsyncMap.rejected, (state, action) => {
                state.tableInfo[action.meta.arg.table].asyncMaps.loading = 'error'
            })
            .addCase(fetchAsyncMap.fulfilled, (state, action) => {
                let {project, table} = action.meta.arg;
                action.payload.result.forEach(r => {
                    let field = schemas[project].tables[table].fields.find(x => x.key === r.key);
                    if (field) {
                        field.map = {
                            values: r!.data
                        }
                        schemas[project].filterSchemas[table][r.key] = {
                            ...schemas[project].filterSchemas[table][r.key],
                            mapSchema: createMapFilterSchema(field.map)
                        }
                    }
                })
                state.tableInfo[table].asyncMaps.loading = 'loaded'
            })
    },
})

type ExtendedQueryDto = QueryParamsDTO & { clean?: true };
export const fetchTableByQuery = createAsyncThunk<{
    query: ExtendedQueryDto,
    result: any
}, Partial<ExtendedQueryDto> | undefined, {
    state: RootState
}>(
    'table/fetchTableByQuery',
    async (payload, thunkAPI) => {
        let state = thunkAPI.getState();
        let tableName = state.tables.activeTable;
        let query = {
            project: state.project.name,
            table: tableName,
            fields: [],
            pages: state.tables.queries[tableName].current.limit,
            pageIndex: payload?.pageIndex || state.tables.tableInfo[tableName].current_page,
            filters: []
        } as ExtendedQueryDto
        query.filters = state.tables.queries[tableName].current.filters.map(f => {
            if (f.filterOp === SqlOperation.LIKE && f.value === '')
                return null
            if ((f.filterOp === SqlOperation.IN || f.filterOp === SqlOperation.NOT_IN) && ((f.value as [])?.length === 0 || f.value === undefined))
                return null

            return f.field && f.filterOp && f.value !== undefined ? {
                field: f.field,
                filterOp: f.filterOp,
                value: f.value
            } as Filter : null
        }).filter(x => x !== null) as Filter[]
        query.order = {
            field: state.tables.queries[tableName].current.orderBy,
            way: state.tables.queries[tableName].current.orderWay
        }
        query.clean = payload?.clean
        let json = {...query}
        delete json.clean
        let jsonStr = JSON.stringify(json);
        let uri = schemas[query.project].backend + '/selectTable.php?query=' + encodeURIComponent(jsonStr);
        const response = await fetch(uri, {credentials: 'include'})
            .then(r => {
                if (r.status === 401) {
                    window.location.href = '/login.html'
                }
                return r
            })
            .then(r => r.json());

        if (response.status === 'error')
            return thunkAPI.rejectWithValue({
                query: query,
                result: response
            })

        return {
            query: query,
            result: response
        }
    },
)

type XlsQueryDto = QueryParamsDTO & { fields: { [key: string]: string }[] };
export const fetchXls = createAsyncThunk<{
    query: ExtendedQueryDto,
    result: any
}, Partial<XlsQueryDto> | undefined, {
    state: RootState
}>(
    'table/fetchXls',
    async (payload, thunkAPI) => {
        let state = thunkAPI.getState();
        let tableName = state.tables.activeTable;
        const lang = 'ua'
        let query = {
            project: state.project.name,
            table: tableName,
            fields: [],
            pages: 0,
            pageIndex: payload?.pageIndex || state.tables.tableInfo[tableName].current_page,
            filters: []
        } as ExtendedQueryDto
        query.filters = state.tables.queries[tableName].current.filters.map(f => {
            if (f.filterOp === SqlOperation.LIKE && f.value === '')
                return null
            if ((f.filterOp === SqlOperation.IN || f.filterOp === SqlOperation.NOT_IN) && ((f.value as [])?.length === 0 || f.value === undefined))
                return null

            return f.field && f.filterOp && f.value !== undefined ? {
                field: f.field,
                filterOp: f.filterOp,
                value: f.value
            } as Filter : null
        }).filter(x => x !== null) as Filter[]
        query.order = {
            field: state.tables.queries[tableName].current.orderBy,
            way: state.tables.queries[tableName].current.orderWay
        }
        let json = {...query}
        let jsonStr = JSON.stringify(json);
        let fieldLang = JSON.stringify(schemas[query.project].tables[tableName].fields.map(f => ({
            value: f.title[lang],
            key: f.key
        })));
        console.log(fieldLang)
        let uri = schemas[query.project].backend + '/getXls.php?query=' + encodeURIComponent(jsonStr)
            + '&langField=' + encodeURIComponent(fieldLang);
        window.open(uri, "_blank")
        return {
            query: query,
            result: {}
        }
    },
)

export const fetchTableCounts = createAsyncThunk<any, string>(
    'table/fetchTableCounts',
    async (payload, thunkAPI) => ({
        project: payload,
        result: await fetch(schemas[payload].backend + '/selectTablesCount.php?project=' + payload, {
            credentials: 'include'
        })
            .then(r => {
                if (r.status === 401) {
                    window.location.href = '/login.html'
                }
                return r
            })
            .then(r => r.json())
    }),
)


export const fetchAsyncMap = createAsyncThunk<{
    payload: { project: string; data: { key: string; queryKey: string }[]; table: string };
    result: { data: any; key: string }[];
}, {
    project: string,
    data: {
        key: string,
        queryKey: string
    }[],
    table: string
}, {
    state: RootState
}>(
    'table/fetchAsyncMap',
    async (payload, thunkAPI) => {
        try {
            const data = {} as any
            const maps = await Promise.all(
                payload.data.map(async x => {
                    const res = await fetch(
                        `${schemas[payload.project].backend}/runQuery.php?project=${payload.project}&query_key=${x.queryKey}`,
                        {credentials: 'include'})
                        .then(r => {
                            if (r.status === 401) {
                                window.location.href = '/login.html'
                            }
                            return r
                        })
                        .then(r => r.json()) as {
                        data: { label: string; value: string | number }[]
                    };

                    res.data.forEach(y => {
                        data[y.value] = {
                            rawValue: y.value,
                            lang: {ua: y.label},
                        }
                    })
                    return {
                        key: x.key,
                        data: data
                    };
                })
            );


            return {
                payload: payload,
                result: maps
            };
        } catch (e) {
            console.log(e)
            // @ts-ignore
            return thunkAPI.rejectWithValue({message: e.message});
        }

    },
)

export const updateSingleTableField = createAsyncThunk<{
    payload: {
        field: string,
        value: Primitive,
        id: number | string
    },
    table: string,
    page: number,
    pkField: string,
    result: any
}, {
    field: string,
    value: Primitive,
    id: number | string
}, {
    state: RootState
}>(
    'table/updateSingleField',
    async (payload, thunkAPI) => {
        let state = thunkAPI.getState();

        let tableName = state.tables.activeTable;
        let project = state.project.name;

        let path
        const schema = schemas[project];
        let field = schema.tables[tableName].fields.find(x => x.key === payload.field);
        if (field?.updateSingleFieldPath)
            path = schema.backend + schema.tables[tableName].backend + field.updateSingleFieldPath;
        else
            path = schema.backend + '/updateField.php';
        let result = await fetch(path, {
            method: "PATCH",
            credentials: 'include',
            body: JSON.stringify({
                project: project,
                table: tableName,
                pk: payload.id,
                field: payload.field,
                value: payload.value
            })
        })
            .then(r => {
                if (r.status === 401) {
                    window.location.href = '/login.html'
                }
                return r
            })
            .then(r => r.json());

        if (result.status === 'error')
            return thunkAPI.rejectWithValue({
                payload: payload,
                table: tableName,
                page: state.tables.tableInfo[tableName].current_page,
                pkField: schema.tables[tableName].pk,
                result: result
            })

        return {
            payload: payload,
            table: tableName,
            page: state.tables.tableInfo[tableName].current_page,
            pkField: schema.tables[tableName].pk,
            result: result
        }
    },
)


export const {
    setInitialTableState,
    setActiveTable,
    changePage,
    changePerPage,
    addNewFilter,
    deleteFilter,
    selectFilterField,
    selectFilterOperation,
    setFilterValue,
    setOrderBy,
    changeOrderWay,
} = tablesSlice.actions

export default tablesSlice.reducer


function defaultFilterValue(type: FieldType) {

    switch (type) {
        case "number":
            return 0
        case "string":
        case "email":
        case "url":
        case "tel":
        case "image":
            return ''
        case "boolean":
            return true
        case "datetime":
        case "time":
        case "date":
            return datetimeDefValue(type)
        case "map":
            return undefined
        default:
            return undefined
    }
}
