import {FieldMap, FieldOperations, OpsCategories, SqlOperation, TableI} from "../../Types";
import moment from "moment";
import {userLevelMap} from "./UsersTable";
import {nanoid} from "@reduxjs/toolkit";


const inmsgChannelMap = {
    backgroundColor: '#18111c',
    values: {
        "VIBER": {
            rawValue: "VIBER",
            icon: 'viber',
            lang: {
                ua: "VIBER",
                en: "START"
            },
            color: '#bc30ee'
        },
        "WEB": {
            rawValue: "WEB",
            icon: 'web',
            lang: {
                ua: "WEB",
                en: "SMART"
            },
            color: '#238dfe'
        }
    }
} as FieldMap;

const inmsgMsgTypeMap = {
    groups: [{
        label: {
            ua: "Коректні",
            en: "START"
        },
        values: ["4"]
    }, {
        label: {
            ua: "Некоректні",
            en: "START"
        },
        values: ["3", "2", "1"]
    }],
    backgroundColor: '#18111c',
    values: {
        "4": {
            rawValue: "4",
            lang: {
                ua: "Коректний",
                en: "START"
            },
            color: '#19eb00'
        },
        "3": {
            rawValue: "3",
            lang: {
                ua: "Дублікат",
                en: "SMART"
            },
            color: '#e61218'
        },
        "2": {
            rawValue: "2",
            lang: {
                ua: "Немає в базі",
                en: "SMART"
            },
            color: '#e61218'
        },
        "1": {
            rawValue: "1",
            lang: {
                ua: "Некоректний",
                en: "SMART"
            },
            color: '#e61218'
        }
    }
} as FieldMap;


let fieldOperations: FieldOperations = {
    dt: [...OpsCategories.range],
    msgtype: [...OpsCategories.in],
    msisdn: [...OpsCategories.eq, ...OpsCategories.like, ...OpsCategories.in,],
    codes: [...OpsCategories.range],
    balls: [...OpsCategories.range],
    balls_for_first: [...OpsCategories.range],
    vac_level: [...OpsCategories.null],
    name: [...OpsCategories.like],
    id: [...OpsCategories.eq, ...OpsCategories.range, ...OpsCategories.in,],
};
export const ImnsgTable: TableI = {
    backend: "/287/",
    title: {
        ua: "Реєстрації",
        en: "Замовлення",
    },
    pk: 'id',
    defaultOrderedBy: "id",
    defaultOrderedWay: "DESC",
    defaultPerPage: 20,
    orderByFields: {
        "id": {ua: "ID", en: "№"},
        "dt": {ua: "Створено", ru: "Дата"},
        "codes": {ua: "Реєстрацій", ru: "Регистраций"},
        "balls": {ua: "Балів за реєстрацію", ru: "Балов за регистрацию"},
        "balls_for_first": {ua: "Додаткове нарахування балів по коду", ru: "Додаткове нарахування балів по коду"},
    },
    fieldOperations: fieldOperations,
    defaultFilters: 'inmsg287DefFilters',
    fields: [
        {
            key: "n",
            inline: true,
            title: {ua: "№", ru: "№"}, type: "number",
            applyTransform: true
        },
        {
            key: "balls",
            title: {ua: "Балів за реєстрацію", ru: "Балов за регистрацию"},
            type: 'number',
            detailed: true,
            inline: false,
            editable: true,
            inlineEditable: true,
            updateSingleFieldPath: 'updateInmsgBalls.php'
        },
        {
            key: "balls_for_first",
            title: {ua: "Додаткове нарахування балів по коду", ru: "Додаткове нарахування балів по коду"},
            type: 'number',
            detailed: true,
            inline: false,
            editable: true,
            inlineEditable: true,
            updateSingleFieldPath: 'updateInmsgBalls.php'
        },
        {
            key: "vac_level",
            title: {ua: "Нараховані бали по рівню", ru: "Нараховані бали по рівню"},
            type: 'string',
            detailed: true,
            inline: false
        },

        {
            key: "dt",
            title: {ua: "Дата", ru: "Дата"},
            type: "datetime",
            datetimeFormat: "DD MMMM YYYY HH:mm",
            applyTransform: true,
            detailed: false,
            inline: true,
        },
        {
            key: "msisdn", type: 'tel',
            applyTransform: true,
            detailed: false, inline: true, title: {ua: "Телефон", ru: "Телефон"}
        },
        {
            key: "message",
            getColumnValue: "act287getInmsgCodeColumnValue",
            title: {ua: "Код", ru: "Код"},
            type: "string",
            applyTransform: true,
            detailed: false,
            inline: true
        },
        {
            key: "sn",
            type: 'map',
            title: {ua: "Канал"},
            detailed: false,
            inline: true,
            applyTransform: true,
            map: inmsgChannelMap
        },
        {
            key: "msgtype",
            title: {ua: "Статус", ru: "Статус"},
            type: "map",
            map: inmsgMsgTypeMap,
            inline: true,
            detailed: false,
            applyTransform: true
        },
        {key: "name", type: 'string', inline: true, title: {ua: "Користувач", ru: "Пользователь"}},

        {key: "product", title: {ua: "Модель", ru: "Модель"}, type: 'string', detailed: true, inline: true},
        {
            key: "balls_from_inviter",
            title: {ua: "Приведи Друга (1 якщо так)", ru: "Приведи Друга (1 если Да)"},
            type: 'string',
            detailed: true,
            inline: false,
            editable: true,
            updateSingleFieldPath: 'updateInmsgBalls.php'
        },
        {key: "codes", title: {ua: "Реєстрацій", ru: "Регистраций"}, type: "number", inline: true},
        {key: "comment", title: {ua: "Коментар", ru: "Комментарий"}, type: 'string',},
        {
            key: "user_level",
            title: {ua: "Рівень користувача", ru: "Уровень пользователя"},
            detailed: true,
            inline: true,
            applyTransform: true,
            type: "map",
            map: userLevelMap,
        },
        {
            key: "id",
            detailed: true,
            title: {ua: "ID", ru: "№"},
            type: "number",
            applyTransform: true
        },
        {key: "ip", title: {ua: "IP", ru: "IP"}, type: 'string', detailed: true, inline: false}
    ]
}

export function inmsg287DefFilters() {
    return [
        {
            nanoid: nanoid(),
            field: 'dt',
            type: 'datetime',
            filterOp: SqlOperation.GREATER_THAN_OR_EQUAL,
            locked: true,
            value: moment().subtract(7, 'days').startOf('day').format('yyyy-MM-DD HH:mm:ss')
        },
        {
            nanoid: nanoid(),
            field: 'dt',
            type: 'datetime',
            filterOp: SqlOperation.LESS_THAN_OR_EQUAL,
            locked: true,
            value: moment().endOf('day').locale('en').format('yyyy-MM-DD HH:mm:ss')
        },
        {
            nanoid: nanoid(),
            field: 'msisdn',
            type: 'tel',
            filterOp: SqlOperation.LIKE,
            locked: true,
            value: ''
        },
        {
            nanoid: nanoid(),
            field: 'msgtype',
            type: 'map',
            filterOp: SqlOperation.IN,
            locked: true,
            value: []
        }
    ]
}
