import React, {useState} from 'react';
import styled from 'styled-components';


const ImageWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;


const Button = styled.button`
    padding: 0.5rem 1rem;
    border: none;
    background-color: var(--table-action-color);
    color: var(--table-row-text-color);
    font-size: 1rem;
    font-weight: bold;
    border-radius: 5px;
    cursor: pointer;

    &:hover {
        filter: brightness(.85);;
    }
`;

export const OverlayContainer = styled.div<{ $isVisible: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  display: ${({ $isVisible }) => ($isVisible ? 'flex' : 'none')};
  align-items: center;
  justify-content: center;
  z-index: 9999;
`;

export const ContentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    max-width: 85vw;
    max-height: 95vh;
    width: 100%;
    height: 100%;
    background: var(--table-row-odd-bg-color);
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.5);
    position: relative;
`;

export const ImageArea = styled.div`
  flex: 1; /* Takes all available space except for ButtonContainer */
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: auto; /* Ensure large images are scrollable if needed */
`;

export const StyledImage = styled.img<{ $rotation: number; $maxWidth: number; $maxHeight: number }>`
  transform: rotate(${({ $rotation }) => $rotation}deg);
  max-width: ${({ $maxWidth }) => $maxWidth}px;
  max-height: ${({ $maxHeight }) => $maxHeight}px;
  object-fit: cover;
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 16px;
  background: var(--table-header-bg-color);
  border-top: 2px solid var(--table-border-color);
`;

const NormalImage = styled.img<{ $maxWidth: number; $maxHeight: number }>`
    max-width: ${(props) => props.$maxWidth}px;
    max-height: ${(props) => props.$maxHeight}px;
    //width: auto;
    height: auto;
    cursor: pointer;
`;

const ImageCard = styled.img<{ $maxWidth: number; $maxHeight: number }>`
    width: 180px;
    height: 130px;
    object-fit: cover;
    
    cursor: pointer;
    
    border-radius: 4px;
    
`;

interface ImageViewerProps {
    src: string;
    maxHeightProp: number
}

export const ImageViewer: React.FC<ImageViewerProps> = ({src, maxHeightProp = 1000}) => {
    const [isOverlayVisible, setOverlayVisible] = useState(false);
    const [rotation, setRotation] = useState(0);
    const [realImageSize, setRealImageSize] = useState<{ width: number; height: number }>({
        width: 0,
        height: 0,
    });

    const handleRotate = () => setRotation((prev) => (prev + 90) % 360);

    const handleSave = () => {
        const link = document.createElement('a');
        link.href = src;
        link.target = "_blank"
        link.download = `image_${Date.now()}.jpg`;
        link.click();
    };

    const handleImageLoad = (e: React.SyntheticEvent<HTMLImageElement>) => {
        const img = e.currentTarget;
        setRealImageSize({
            width: img.naturalWidth,
            height: img.naturalHeight,
        });
    };

    const close = () => {
        setOverlayVisible(false)
    }

    // Determine the max dimensions based on screen size and real image size
    const maxScreenWidth = window.innerWidth * 0.85;
    const maxScreenHeight = window.innerHeight * 0.85;
    const maxWidth = Math.min(realImageSize.width, maxScreenWidth);
    const maxHeight = Math.min(realImageSize.height, maxScreenHeight);

    return (
        <>
            {/* Normal Mode */}
            {/*<NormalImage src={src} alt="Thumbnail" $maxWidth={maxWidth}*/}
            {/*             $maxHeight={Math.min(maxHeight, maxHeightProp)} onClick={() => setOverlayVisible(true)}/>*/}

            <ImageCard src={src} alt="Thumbnail" $maxWidth={maxWidth}
                         $maxHeight={Math.min(maxHeight, maxHeightProp)} onClick={() => setOverlayVisible(true)}/>
            {/* Overlay Mode */}
            <OverlayContainer $isVisible={isOverlayVisible} onClick={close}>
                <ContentWrapper onClick={(e) => e.stopPropagation()}>
                    <ImageArea>
                        <StyledImage
                            src={src}
                            alt="Full view"
                            $rotation={rotation}
                            $maxWidth={maxWidth}
                            $maxHeight={maxHeight}
                            onLoad={handleImageLoad}
                        />
                    </ImageArea>
                    <ButtonContainer>
                        <Button onClick={handleRotate}>↪</Button>
                        <Button onClick={handleSave}>💾</Button>
                        <Button onClick={close}>❌</Button>
                    </ButtonContainer>
                </ContentWrapper>
            </OverlayContainer>
        </>
    );
};
export default ImageViewer
