import React, {useState} from 'react';
import styled from "styled-components";
import moment from "moment/moment";
import {FilterSchema} from "../../287/tables/UsersTable";
import {useAppDispatch} from "../../hooks";
import {setFilterValue} from "../../features/tables/tableSlice";
import {FieldType} from "../../Types";

export function datetimeDefValue(type: FieldType, val: any = null) {
    let mo = val ? moment(val) : moment()
    if (type === 'datetime')
        return mo.locale('en').format('yyyy-MM-DDTHH:mm');
    if (type === 'date')
        return mo.locale('en').format('yyyy-MM-DD');
    if (type === 'time')
        return moment(val || new Date(), [moment.ISO_8601, 'HH:mm'])
            .format("HH:mm");
    return ''
}

function format(schema: FilterSchema, value: string) {
    if (schema.type === 'datetime') {
        const datetime = moment(value, moment.ISO_8601, true);
        return datetime.isValid() ? datetime.locale('en').format('yyyy-MM-DD HH:mm:ss') : '';
    }

    if (schema.type === 'date') {
        const date = moment(value, 'YYYY-MM-DD', true); // Strict match for date
        return date.isValid() ? date.locale('en').format('yyyy-MM-DD') : '';
    }

    if (schema.type === 'time') {
        const time = moment(value, [moment.ISO_8601, 'HH:mm'], true); // Strict match for time
        return time.isValid() ? time.format('HH:mm') : '';
    }

    return ''; // Default fallback for unsupported types
}

function DatetimeFilterValue({index, schema, arrPos, initialValue}: {
    index: number,
    schema: FilterSchema,
    arrPos?: 0 | 1,
    initialValue: any
}) {
    const dispatch = useAppDispatch();
    let [value, setValue] = useState<string>(initialValue ?
        datetimeDefValue(schema.type, arrPos !== undefined ? initialValue[arrPos] : initialValue)
        : datetimeDefValue(schema.type));


    // useEffect(() => {
    //     dispatch(setFilterValue({index, value: format(schema, value)}))
    // });

    function onChange(e: React.ChangeEvent<HTMLInputElement>) {
        let val = e.currentTarget.value;
        setValue(val);
        let formatted = format(schema, val);
        if (formatted === '')
            dispatch(setFilterValue({index, value: undefined, arrPos}));
        else
            dispatch(setFilterValue({index, value: formatted, arrPos}));
    }

    return <Container $maxHeight={arrPos === undefined}>
        <input type={schema.type === 'datetime' ? 'datetime-local' : schema.type}
               value={value}
               onChange={(e) => onChange(e)}/>
    </Container>
}

export default DatetimeFilterValue;

const Container = styled.div<any>`
    height: ${props => props.$maxHeight ? '100%' : 'unset'};
    width: 100%;

    & .wrapper {
        display: flex;
        gap: .5rem;
        justify-content: start;

    }

    & input {
        width: 100%;
        height: 100%;
        box-sizing: border-box;
        font-size: 16px;
        border: none;
    }


`

