import {FieldOperations, OpsCategories, SqlOperation, TableI} from "../../Types";
import {nanoid} from "@reduxjs/toolkit";


let fieldOperations: FieldOperations = {
    dt: [...OpsCategories.range],
    msisdn: [...OpsCategories.eq, ...OpsCategories.like, ...OpsCategories.in,],
    topic: [...OpsCategories.eq, ...OpsCategories.like, ...OpsCategories.in,],
    email: [...OpsCategories.eq, ...OpsCategories.like, ...OpsCategories.in,],
    id: [...OpsCategories.eq, ...OpsCategories.range, ...OpsCategories.in,],
};

export function webinars287DefFilters() {
    return [
        {
            nanoid: nanoid(),
            field: 'msisdn',
            type: 'tel',
            filterOp: SqlOperation.LIKE,
            locked: true,
            value: ''
        }, {
            nanoid: nanoid(),
            field: 'email',
            type: 'email',
            filterOp: SqlOperation.LIKE,
            locked: true,
            value: ''
        },{
            nanoid: nanoid(),
            field: 'topic',
            type: 'string',
            filterOp: SqlOperation.LIKE,
            locked: true,
            value: ''
        },
    ]
}

export const WebinarsTable: TableI = {
    backend: "/287/",
    title: {
        ua: "Вебінари",
        en: "Замовлення",
    },
    pk: 'id',
    defaultOrderedBy: "id",
    defaultOrderedWay: "DESC",
    defaultPerPage: 20,
    orderByFields: {
        "id": {ua: "ID", en: "№"},
        "dt": {ua: "Створено", ru: "Створено"},
    },
    fieldOperations: fieldOperations,
    defaultFilters: 'webinars287DefFilters',
    fields: [
        {
            key: "n",
            inline: true,
            title: {ua: "№", ru: "№"}, type: "number",
            applyTransform: true
        },
        {
            key: "dt",
            title: {ua: "Створено", ru: "Дата"},
            type: "datetime",
            datetimeFormat: "DD MMMM YYYY HH:mm",
            applyTransform: true,
            inline: true,
            detailed: true
        },
        {
            key: "msisdn", type: 'tel',
            applyTransform: true,
            detailed: true, inline: true,
            title: {ua: "Телефон", ru: "Телефон"}
        },
        {
            key: "email",
            title: {ua: "Email", ru: "Тема"},
            detailed: true,
            inline: true,
            type: 'email'
        },
        {
            key: "topic",
            title: {ua: "Тема", ru: "Тема"},
            detailed: true,
            inline: true,
            type: 'string'
        },
        {
            key: "np", title: {ua: "Нова Почта", ru: "Нова Пошта"},
            detailed: true,
            inline: true,
            type: 'string'
        },
        {
            key: "date",
            title: {ua: "Дата", ru: "Дата"},
            type: "date",
            datetimeFormat: "DD.MM.YYYY",
            applyTransform: false,
            inline: true,
            detailed: true
        },
        {
            key: "id",
            detailed: true,
            title: {ua: "ID", ru: "№"},
            type: "number",
            applyTransform: true
        },
    ]
}
