import {GetColumnValueFuncI} from "../Types";
import styled from "styled-components";
import React from "react";
import CopyButton from "./misc/CopyButton";
import moment from "moment";
import ImageViewer from "./misc/ImageViewer";
import {functions} from "../config";
import {faCheck, faGlobe, faXmark} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faViber} from "@fortawesome/free-brands-svg-icons";

export const getColumnValue: GetColumnValueFuncI = (value, schema, forHeader = false, record = null) => {

    const nullColumn = <div className={forHeader ? 'text-align-center' : ''}>{forHeader ? "" : <i>NULL</i>}</div>;

    if (value === null)
        return nullColumn
    if (value === undefined) {
        return <div className={forHeader ? 'text-align-center' : ''} style={{color: 'yellow'}}><i>Value is missing</i>
        </div>;
    }

    if (!schema.applyTransform)
        return value
    if (schema.applyTransform && schema.getColumnValue !== undefined) {
        return functions[schema.getColumnValue].apply(null, [value, schema, forHeader, record])
        //return schema.getColumnValue(value, schema, forHeader, record);
    }
    if (schema.type === 'number' && forHeader)
        return <div className={'text-align-center'}>{value}</div>;
    if (schema.type === 'boolean') {
        // return <BoolContainer $forHeader={forHeader} style={{color: value ? '#3dbf25' : 'red'}}>
        //     <div>{value ? 'Так' : 'Ні'}</div>
        // </BoolContainer>;
        let v = value ? 'Так' : 'Ні' as any
        if (forHeader)
            v = <FontAwesomeIcon title={value ? 'Так' : 'Ні'} icon={value ? faCheck : faXmark}
                                 style={{width: '1rem', height: '1rem'}}
                                 className={'fa-color-bool'}/>
        return <BoolContainer $forHeader={forHeader} $checked={value}>
            {v}
        </BoolContainer>;
    }
    if (schema.type === 'url')
        return <Link target={'_blank'} href={value}>{value}</Link>
    if (schema.type === 'tel') {
        const v = value.startsWith('+') ? value : '+' + value;
        return <PhoneCol $monospace $center={forHeader}>{value}<CopyButton copyValue={value}/></PhoneCol>
    }
    if (schema.type === 'email') {
        return <PhoneCol>{value}<CopyButton copyValue={value}/></PhoneCol>
    }
    if (schema.type === 'image') {
        if (forHeader)
            return value
        return <ImageCol>
            <Link target={'_blank'} href={value}>{value}</Link>
            <hr/>
            <ImageViewer src={value} maxHeightProp={schema.imageMaxHeight || 500}/>
        </ImageCol>
    }
    if (schema.type === 'map') {
        let mapValue = schema.map?.values?.[value];
        let v = mapValue?.lang['ua'] || nullColumn
        let icon = mapValue?.icon;
        if (forHeader && icon && icons[icon]) {
            return <div className={'text-align-center'} style={{color: mapValue?.color}}>
                <FontAwesomeIcon icon={icons[icon]} title={mapValue!.rawValue as string}
                                 style={{width: '1.2rem', height: '1.2rem'}}/>
            </div>
        }
        return <div style={{backgroundColor: schema.map?.backgroundColor, color: mapValue?.color}}
                    className={'map-colored-value'}>{v}</div>;
    }
    if (schema.type === 'datetime' || schema.type === 'date' || schema.type === 'time') {
        if (schema.type === 'time')
            return <div
                className={forHeader ? 'text-align-center' : ''}>{moment(value, [moment.ISO_8601, schema.datetimeFormat ?? 'HH:mm'])
                .format(schema.datetimeFormat)}</div>;
        return moment(value).format(schema.datetimeFormat);
    }

    return value;
}

const PhoneCol = styled.div<any>`
    display: inline-flex;
    //align-items: center;
    justify-content: ${props => props.$center ? 'center' : 'left'};
    ${props => props.$monospace && 'font-family: monospace;'};
    width: 100%;
    gap: .4rem;
    white-space: nowrap; /* Prevents wrapping */
    flex-grow: 0;
    flex-shrink: 1;
    
    font-size: 1rem;

    & > img {
        flex-shrink: 0;
        min-width: 1.5rem;
        min-height: 1.5rem;
    }

    & span {
        //vertical-align: middle;
    }

    //vertical-align: middle;

`

const icons = {
    viber: faViber,
    web: faGlobe
} as any

const BoolContainer = styled.div<any>`
    justify-content: center;
    display: ${props => props.$forHeader ? 'inline-flex' : 'block'};
    width: ${props => props.$forHeader ? '100%' : 'auto'};

    & .fa-color-bool {
        path {
            fill: ${props => props.$checked ? '#3dbf25' : 'red'};
            stroke: ${props => props.$checked ? '#3dbf25' : 'red'};
        }
    }

    //& div {
    //    width: 2rem;
    //    padding: 0 .2rem;
    //    text-align: center;
    //    background-color: #3b3939;
    //}
`

const Link = styled.a`
    color: var(--table-action-color);

    &:after {
        content: ' ⭷';
        color: var(--table-row-text-color);
    }

    &:visited {
        color: lightpink;
    }
`

const ImageCol = styled.div<any>`
    //display: inline-flex;
    //flex-flow: column;
    justify-content: left;
    width: 100%;
    gap: .4rem;

    & hr {
        background-color: var(--table-action-color);
        height: 2px;
        width: 100%;
        margin: .2rem 0;
    }

    //& > img {
    //    flex-shrink: 0;
    //    width: 100%;
    //    height: auto;
    //}

    & span {
        vertical-align: middle;
    }

    vertical-align: middle;

`
